/** @type {('local' | 'dev' | 'staging' | string)} */
const VUE_APP_VERSION = process.env.VUE_APP_VERSION
/** @type {('production' | 'development')} */
const ENV = process.env.NODE_ENV
/** @type {string} */
const VUE_APP_SEMVERSION = extractSemVer(VUE_APP_VERSION)
/** @type {boolean} */
const ENV_DEV = ENV === 'development'
/** @type {boolean} */
const ENV_DEV_OR_STAGING = ENV_DEV || VUE_APP_VERSION === 'staging'
/** @type {boolean} */
const ENV_PRODUCTION = ENV === 'production'
/** @type {boolean} */
const ENV_HACKERONE = Boolean(process.env.VUE_APP_IS_HACKERONE)
/** @type {boolean} */
const ENV_LOCAL = VUE_APP_VERSION === 'local'

function env(varName, defaultValue) {
	return process.env[`VUE_APP_${varName}`] || defaultValue
}

function extractSemVer(version) {
	if (ENV === 'production' && version?.startsWith('v')) {
		return version.replace('v', '')
	}

	return version
}

module.exports = {
	ENV,
	VUE_APP_VERSION,
	VUE_APP_SEMVERSION,
	ENV_LOCAL,
	ENV_DEV,
	ENV_DEV_OR_STAGING,
	ENV_PRODUCTION,
	ENV_HACKERONE,
	env,
}
