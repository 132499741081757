import config from '@/config'

export default {
	id: 'SIMPLE_UPGRADE_3M_59',
	providers: {
		stripe: config('stripe_cheap_and_short_upgrade_3_month'),
		braintree: 'pro_short_cheap_upgrade',
		paltaMap: {
			USD: 'simple_premium_3m_59_usd',
			EUR: 'simple_premium_3m_59_eur',
			GBP: 'simple_premium_3m_59_gbp',
			AUD: 'simple_premium_3m_89_aud',
			CAD: 'simple_premium_3m_89_cad',
			DKK: 'simple_premium_3m_439_dkk',
			SEK: 'simple_premium_3m_699_sek',
			NOK: 'simple_premium_3m_699_nok',
			BRL: 'simple_premium_3m_289_brl',
			MXN: 'simple_premium_3m_979_mxn',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 59,
			EUR: 59,
			GBP: 59,
			AUD: 89,
			CAD: 89,
			DKK: 439,
			SEK: 699,
			NOK: 699,
			BRL: 289,
			MXN: 979,
		},
	},
	options: () => ({}),
}
