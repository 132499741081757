<template>
	<div class="notification">
		<div class="">{{ message }}</div>
		<div class="close" @click="close">
			<i class="icon-plus"></i>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Notification',

	props: {
		// eslint-disable-next-line vue/require-default-prop
		message: [String],
	},

	methods: {
		close() {
			this.$store.commit('resetNotification')
		},
	},

	created() {
		setTimeout(this.close, 3000)
	},
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.notification {
	position: fixed;
	top: 15px;
	left: 50%;
	background: #feebec;
	border: 2px solid #f43843;
	border-radius: 0.7rem;
	width: calc(100% - 2rem);
	max-width: 460px;
	color: #f43843;
	padding: 0.84rem 1.2rem;
	transform: translateX(-50%);
	font-size: 0.9rem;
	padding-right: 3rem;
	line-height: 1.4;
	z-index: 10000;

	.close {
		position: absolute;
		right: 1rem;
		top: 50%;
		transform: translateY(-50%);
		font-size: 0.94rem;
		color: #e3aeae;
		opacity: 1;

		.icon-plus {
			transform: rotate(45deg);
			display: block;
		}
	}
}
</style>
