import Decimal from 'decimal.js'
import Long from 'long'

import { ENV_DEV_OR_STAGING, VUE_APP_SEMVERSION } from '@/helpers/environment'
import Sentry from '@/modules/Sentry'

class Jet {
	constructor() {
		/** @type {typeof import('../data/eventSchema/analytics').default|null} */
		this._instance = null
		this._loggerColor = {
			event: window.matchMedia('(prefers-color-scheme: dark)')?.matches ? '#ffcb6b' : '#f76d47',
			context: window.matchMedia('(prefers-color-scheme: dark)')?.matches ? '#82aaff' : '#6182b8',
		}

		this._isInited = new Promise((resolve) => {
			this._isInitedResolve = resolve
		})

		this.#init()
	}

	async #init() {
		const { default: analytics } = await import(/* webpackChunkName: "jet" */ '@/data/eventSchema/analytics')

		this._instance = analytics

		this._instance.init({
			logger: (error) => {
				Sentry.withScope((scope) => {
					scope.setTags({
						jetError: true,
						jetValidation: true,
					})
					scope.captureException(error)
				})
			},
			showLogs: ENV_DEV_OR_STAGING,
		})

		this.setApplication({
			appId: 'WebFunnel',
			appPlatform: 'Web',
			appVersion: VUE_APP_SEMVERSION,
			appLanguage: window.language,
		})

		this._isInitedResolve()
	}

	#onError(error, errorTags = {}) {
		Sentry.withScope((scope) => {
			scope.setTags({
				jetError: true,
				...errorTags,
			})
			scope.captureMessage(error)
		})
	}

	#objectValuesToEnums(object, enums) {
		return Object.fromEntries(
			Object.entries(object).map((entry) => {
				return [entry[0], enums[entry[1]]]
			}),
		)
	}

	#normalizeNumbers(obj) {
		const normalizedObj = {}

		if (obj === null || typeof obj !== 'object') {
			return normalizedObj
		}

		for (const [key, value] of Object.entries(obj)) {
			if (Long.isLong(value)) {
				normalizedObj[key] = value.toNumber()
			} else if (value instanceof Decimal) {
				normalizedObj[key] = value.toNumber()
			} else {
				normalizedObj[key] = value
			}
		}

		return normalizedObj
	}

	#logger(evenrName, eventPayload, options = {}) {
		const color = this._loggerColor[options.color] || this._loggerColor.event

		if (ENV_DEV_OR_STAGING) {
			/* eslint-disable no-console */
			console.groupCollapsed(`%cJet [debug]: %c${evenrName}`, 'font-weight: 400', `color: ${color}`)
			console.log(this.#normalizeNumbers(eventPayload))
			console.groupEnd()
			/* eslint-enable no-console */
		}
	}

	async titleEnumExists(title) {
		await this._isInited

		return this._instance.enums.TitleEnum.hasOwnProperty(title)
	}

	/**
	 * @param {import('../data/eventSchema/analytics').context.setApplication} appContext TODO
	 * @returns {Promise<void>}
	 */
	async setApplication(appContext) {
		await this._isInited

		this._instance.context.setApplication(appContext)
		this.#logger('ApplicationContext', appContext, { color: 'context' })
	}

	/**
	 * @param {import('../data/eventSchema/analytics').context.setDevice} deviceContext TODO
	 * @returns {Promise<void>}
	 */
	async setDevice(deviceContext) {
		await this._isInited

		this._instance.context.setDevice(deviceContext)
		this.#logger('DeviceContext', deviceContext, { color: 'context' })
	}

	/**
	 * @param {import('../data/eventSchema/analytics').context.setOnboarding} onboardingContext TODO
	 * @returns {Promise<void>}
	 */
	async setOnboarding(onboardingContext) {
		await this._isInited

		this._instance.context.setOnboarding(onboardingContext)
		this.#logger('OnboardingContext', onboardingContext, { color: 'context' })
	}

	/**
	 * @param {import('../data/eventSchema/analytics').context.setOnboarding} userContext TODO
	 * @returns {Promise<void>}
	 */
	async setUser(userContext) {
		await this._isInited

		this._instance.context.setUser(userContext)
		this.#logger('UserContext', userContext, { color: 'context' })
	}

	/**
	 * @param {import('../data/eventSchema/analytics').context.setCancellation} cancellationContext TODO
	 * @returns {Promise<void>}
	 */
	async setCancellation(cancellationContext) {
		await this._isInited

		this._instance.context.setCancellation(cancellationContext)
		this.#logger('CancellationContext', cancellationContext, { color: 'context' })
	}

	/**
	 * OnboardingStart, OnboardingFinish, OnboardingPaywallShow
	 * @param {keyof typeof import('../data/eventSchema/analytics').events.Core} eventName
	 * @param {Record} [eventPayload]
	 * @returns {Promise<void>}
	 */
	async logCoreEvent(eventName, eventPayload) {
		await this._isInited

		const eventFn = this._instance.events.Core[eventName]

		if (typeof eventFn !== 'function') {
			return this.#onError(`Jet: Unknown event name - ${eventName}`, { jetEventName: eventName })
		}

		eventFn(eventPayload).track()
		this.#logger(eventName, eventPayload, { color: 'event' })
	}

	/**
	 * @param {keyof typeof import('../data/eventSchema/analytics').events.Logical} eventName
	 * @param {Record} [eventPayload]
	 * @returns {Promise<void>}
	 */
	async logLogicalEvent(eventName, eventPayload) {
		await this._isInited

		const eventFn = this._instance.events.Logical[eventName]

		if (typeof eventFn !== 'function') {
			return this.#onError(`Jet: Unknown event name - ${eventName}`, { jetEventName: eventName })
		}

		eventFn(eventPayload).track()
		this.#logger(eventName, eventPayload, { color: 'event' })
	}

	/**
	 * ScreenView, ButtonClick, PopupView, PopupClose, BlockView, BlockScroll
	 * @param {keyof typeof import('../data/eventSchema/analytics').events.Ux} eventName
	 * @param {{
	 * 		title?: keyof typeof import('../data/eventSchema/analytics').enums.TitleEnum,
	 * 		path?: (keyof typeof import('../data/eventSchema/analytics').enums.TitleEnum)[]
	 * }} eventPayload
	 * @returns {Promise<void>}
	 */
	async logUxEvent(eventName, eventPayload) {
		await this._isInited

		const eventFn = this._instance.events.Ux[eventName]

		if (typeof eventFn !== 'function') {
			return this.#onError(`Jet: Unknown event name - ${eventName}`, { jetEventName: eventName })
		}

		let event = eventFn()

		if (!eventPayload) {
			return this.#onError(`Jet: Empty trackingHeaders - ${eventName}`, { jetEventName: eventName })
		} else {
			if (typeof event.setTrackingHeader === 'function') {
				event.setTrackingHeader(this.#objectValuesToEnums(eventPayload, this._instance.enums.TitleEnum))
			} else {
				event = eventFn(eventPayload)
			}
		}

		event.track()
		this.#logger(eventName, eventPayload, { color: 'event' })
	}
}

/** @type {Jet} */
const jet = new Jet()

/**
 * @param values
 * @returns {number|number[]}
 */
export function typeDecimal(values) {
	if (Array.isArray(values)) {
		return values.map((n) => new Decimal(n))
	}

	return new Decimal(values)
}

/**
 * @param number
 * @returns {number}
 */
export function typeLong(number) {
	return new Long(number)
}

export default jet
