export const LS_EXPIRATION_MINUTES = 7 * 24 * 60 // one week

export const VALUES_MAP_FOR_LEGACY_ANALYTICS_BY_PROPERTY = {
	intermittentFasting: {
		just_name: 'Just the name',
		few_things: 'I know a few things',
		everything: 'Yes, I know everything about it!',
	},
	meals: {
		1: '1-2',
		2: '2-3',
		3: '3-4',
		4: '4+',
	},
	carbs: {
		often: 'very_often',
		sometimes: 'sometimes',
		rarely: 'rarely',
		never: 'not_at_all',
	},
	sleep: {
		long_time_fall_asleep: 'long_time',
		go_after_11: 'after_11pm',
		less_than_7: '7_hours',
		more_than_10: '10_hours',
		never_feel_rested: 'restless_sleep',
		'8_hours': '8_hours',
	},
	work: {
		sedentary: 'sedentary',
		moderately_active: 'mid_active',
		very_active: 'phisically_demanding',
	},
	age: {
		'18-29': '18-29',
		'30-39': '30-39',
		'40-49': '40-49',
		'50-59': '50-59',
		'60-69': '60-69',
		'70+': '70-70',
	},
	motivation_event: {
		vacation: 'vacation',
		wedding: 'wedding',
		competition: 'competition',
		summer: 'summer',
		school: 'reunion',
		family: 'family',
		birthday: 'birthday',
		dont_have: 'no',
	},
	rq_1: {
		diet_activity_need_impoving: 'My diet and activity need a lot of fine-tuning',
		diet_activity_could_improved: 'I try to practice healthy habits',
		trying_healthy_lifestyle: 'I mostly eat well and stay active',
	},
	rq_2: {
		sandwiches_wraps: 'I tend to eat sandwiches or wraps',
		soups_salads: 'I tend to eat soups and salads',
		fast_food: 'Fast food',
	},
	rq_3: {
		three_or_fewer: 'I almost always eat three or fewer meals per day',
		three_and_snacks: 'I almost always eat at least three meals, plus several snacks a day',
		it_depends: 'It depends — sometimes less than three meals, sometimes more',
	},
	bodyType: {
		circle: 'rounded',
		triangle: 'triangle',
		inverted_triangle: 'inverted_triangle',
		rectangle: 'rectangle',
		hourglass: 'hourglass',
	},
	health_conditions: {
		anorexia: 'anorexia',
		bulimia: 'bulimia',
		diabetes: 'diabetes',
		high_blood_pressure: 'high_blood_pressure',
		high_cholesterol: 'high_cholesterol',
		mental_health_issues: 'stress_anxiety_depression',
		other: 'other',
		dont_have: 'none',
	},
	vitamins: 'vitamins',
	hormones: 'hormones',
	antibiotics: 'antibiotics',
	other: 'other',
	not_taking: 'none_of_this',
}

export const SUPPORTED_CURRENCIES = ['EUR', 'CAD', 'GBP', 'AUD', 'DKK', 'SEK', 'NOK', 'BRL', 'MXN']

export const GDPR_COUNTRIES = [
	'AT', // Austria
	'BE', // Belgium
	'BG', // Bulgaria
	'CY', // Cyprus
	'CZ', // Czech Republic
	'DE', // Germany
	'DK', // Denmark
	'EE', // Estonia
	'ES', // Spain
	'FI', // Finland
	'FR', // France
	'GB', // United Kingdom
	'GR', // Greece
	'HU', // Hungary
	'IE', // Ireland
	'IS', // Iceland
	'IT', // Italy
	'LT', // Lithuania
	'LU', // Luxembourg
	'LV', // Latvia
	'MT', // Malta
	'NL', // Netherlands
	'NO', // Norway
	'PL', // Poland
	'PT', // Portugal
	'RO', // Romania
	'SE', // Sweden
	'SI', // Slovenia
	'SK', // Slovakia
]

export const ANALYTIC_AGE_TO_AGE_RANGE_MAP = {
	18: '18-29',
	30: '30-39',
	40: '40-49',
	50: '50-59',
	60: '60+',
}
