export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_3M_14_95',
	providers: {
		paltaMap: {
			USD: 'simple_premium_comm_1m_29_99_3m_14_95_usd',
			CAD: 'simple_premium_comm_1m_49_99_3m_24_95_cad',
			GBP: 'simple_premium_comm_1m_29_99_3m_14_95_gbp',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 14.95,
			CAD: 24.95,
			GBP: 14.95,
		},
		originalPriceMap: {},
		pricePerDayMap: {
			USD: 1,
			CAD: 1.66,
			GBP: 1,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
			CAD: 49.99,
			GBP: 29.99,
		},
	},
	options: ($t) => ({
		commitment: true,
		commitmentPeriodMonths: 3,
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '3-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
