<template>
	<div class="card-skeleton">
		<div class="input-skeleton">
			<div class="input-skeleton__label skeleton-loader"></div>
			<div class="input-skeleton__field skeleton-loader">
				<div class="placeholder">
					<img
						src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzEiIGhlaWdodD0iMjEiIHZpZXdCb3g9IjAgMCAzMSAyMSIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGcgY2xpcC1wYXRoPSJ1cmwoI2NsaXAwXzM0NTZfNDcxNTApIj4KPHJlY3Qgd2lkdGg9IjMxIiBoZWlnaHQ9IjIwLjEwODEiIGZpbGw9IiNEQkRCREIiLz4KPHJlY3QgeT0iMy4wMTYyNCIgd2lkdGg9IjMxIiBoZWlnaHQ9IjQuMzU2NzYiIGZpbGw9ImJsYWNrIi8+CjxyZWN0IHg9IjIuNDY1OTQiIHk9IjkuNzE4OTMiIHdpZHRoPSIyNi4wNjgyIiBoZWlnaHQ9IjIuNjgxMDgiIHJ4PSIxLjM0MDU0IiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjY1Ii8+CjxyZWN0IHg9IjIxLjEzNjUiIHk9IjE1LjA4MTEiIHdpZHRoPSI3LjM5NzcyIiBoZWlnaHQ9IjIuNjgxMDgiIHJ4PSIxLjM0MDU0IiBmaWxsPSJ3aGl0ZSIgZmlsbC1vcGFjaXR5PSIwLjY1Ii8+CjwvZz4KPGRlZnM+CjxjbGlwUGF0aCBpZD0iY2xpcDBfMzQ1Nl80NzE1MCI+CjxyZWN0IHdpZHRoPSIzMSIgaGVpZ2h0PSIyMC4xMDgxIiBmaWxsPSJ3aGl0ZSIvPgo8L2NsaXBQYXRoPgo8L2RlZnM+Cjwvc3ZnPgo="
						alt="Card Logo"
					/>
					1234 1234 1234 1234
				</div>
			</div>
		</div>
		<div class="half-inputs">
			<div class="input-skeleton half">
				<div class="input-skeleton__label skeleton-loader"></div>
				<div class="input-skeleton__field skeleton-loader">
					<div class="placeholder">XX/XX</div>
				</div>
			</div>
			<div class="input-skeleton half">
				<div class="input-skeleton__label skeleton-loader"></div>
				<div class="input-skeleton__field skeleton-loader">
					<div class="placeholder">***</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'CardSkeleton',
}
</script>

<style lang="scss" scoped>
.card-skeleton {
	display: flex;
	flex-direction: column;
	gap: 11px;
}

.input-skeleton {
	display: flex;
	flex-direction: column;
	gap: 6px;

	&__label {
		width: 50%;
		max-width: 140px;
		height: 18px;
		background-color: var(--input-bg-color-disabled);
		border-radius: 5px;
	}

	&__field {
		height: 50px;
		background-color: var(--input-bg-color-disabled);
		border-radius: 5px;
		position: relative;
	}
}

.placeholder {
	position: absolute;
	top: 50%;
	left: 12px;
	transform: translateY(-50%);
	font-size: 1.1rem;
	color: var(--text-color-secondary);
	pointer-events: none;

	img {
		margin-right: 6px;
		width: 24px;
	}
}

.half-inputs {
	display: flex;
	justify-content: space-between;
	gap: 10px;
}

.half {
	flex: 1;
}

.skeleton-loader {
	animation: loading 2s infinite;
}

@keyframes loading {
	0% {
		opacity: 0.5;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.5;
	}
}
</style>
