const LIGHT_THEME = 'light'
const DARK_THEME = 'dark'
const NAVY_THEME = 'navy'

const THEME_COLORS = {
	[LIGHT_THEME]: {
		labelColor: '#6c6c6c',
		borderColor: '#E4E4E4',
		backgroundColor: '#fff',
		backgroundColorOnFocus: '#fff',
		textColor: '#323653',
		placeholderColor: '#A9A9A9',
		borderColorOnFocus: '#b9d5f9',
		applePayButtonStyle: 'black',
		errorTextColor: '#FF0014',
	},
	[DARK_THEME]: {
		labelColor: '#8E90A1',
		borderColor: '#98A4FF',
		backgroundColor: '#2C3158',
		backgroundColorOnFocus: '#2C3158',
		textColor: '#fff',
		placeholderColor: '#80849D',
		borderColorOnFocus: '#8EA4FF',
		applePayButtonStyle: 'white',
		errorTextColor: '#ff95a0',
	},
	[NAVY_THEME]: {
		labelColor: '#8E90A1',
		borderColor: '#CFD4D9',
		backgroundColor: '#181A25',
		backgroundColorOnFocus: '#181A25',
		textColor: '#fff',
		placeholderColor: '#FFFFFF99',
		borderColorOnFocus: '#8EA4FF',
		applePayButtonStyle: 'white',
		errorTextColor: '#ff95a0',
	},
}

export const getDefaultBraintreeCardOptions = ({ theme }) => {
	const colors = THEME_COLORS[theme] || THEME_COLORS[LIGHT_THEME]

	return {
		input: {
			'font-size': '16px',
			color: colors.textColor,
		},
		':focus': {
			color: colors.textColor,
		},
	}
}

export const getDefaultCardOptions = ({ theme }) => {
	const colors = THEME_COLORS[theme] || THEME_COLORS[LIGHT_THEME]

	return {
		vault: {
			visible: false,
		},

		vaultMenu: {
			label: {
				color: '#f80000',
			},
		},

		paymentMethodButton: {
			background: '#A900A9',
		},

		card: {
			cardholderName: {
				visible: false,
			},
		},

		style: {
			loadingScreen: false,
			focusCheckoutOnInit: false,
			inputLabel: {
				color: colors.labelColor,
				fontSize: 12.6,
				lineHeight: 1,
			},
			inputErrorText: {
				color: colors.errorTextColor,
			},
			input: {
				base: {
					borderColor: colors.borderColor,
					borderRadius: 8,
					borderWidth: 2,
					background: colors.backgroundColor,
					color: colors.textColor,
					height: 48,
					fontSize: 16,
					placeholder: {
						color: colors.placeholderColor,
					},
					focus: {
						borderWidth: 2,
						borderColor: colors.borderColorOnFocus,
						background: colors.backgroundColorOnFocus,
					},
				},
				error: {
					background: colors.backgroundColor,
					borderColor: '#c40014',
					focus: {
						borderWidth: 2,
						borderColor: colors.borderColorOnFocus,
						background: colors.backgroundColorOnFocus,
					},
				},
			},
			formSpacings: {
				betweenInputs: '11px',
				betweenLabelAndInput: 11,
			},
			submitButton: {
				base: {
					color: '#ffffff',
					background: '#7d8bf7',
					borderRadius: '.45rem',
				},
			},

			showMorePaymentMethodsButton: {
				base: {
					color: '#6A6D82',
					fontSize: 14,
				},
			},
		},
	}
}

export const getDefaultPaypalOptions = () => {
	return {
		buttonColor: 'gold',
		buttonShape: 'pill',
		buttonSize: 'large',
		buttonLabel: 'buynow',
		paymentFlow: 'PREFER_VAULT',
		buttonHeight: 48,
		container: '#primer-paypal-button',
	}
}

export const getDefaultApplePayOptions = ({ theme }) => {
	const colors = THEME_COLORS[theme] || THEME_COLORS[LIGHT_THEME]

	return {
		container: '#applepay-button',
		buttonType: 'subscribe',
		buttonStyle: colors.applePayButtonStyle,
		captureBillingAddress: true,
	}
}
