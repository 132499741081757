import { round } from './utils'

const LBS_TO_KG = 2.205
const ST_TO_KG = 6.35029318
const FT_TO_CM = 30.48

export const BMI_MIN = 18.5

export const convertWeights = (value, system = 'kg') => {
	if (!value) return ''

	if (system === 'st') {
		return Math.floor(value / ST_TO_KG)
	}
	if (system === 'lbs') {
		return Math.floor(value * LBS_TO_KG)
	}
	return Math.floor(value)
}

export const convertWeightsRound = (value, system = 'kg') => {
	value = value ?? null
	if (value === null) return ''

	if (system === 'st') {
		return Math.round(value / ST_TO_KG)
	}
	if (system === 'lbs') {
		return Math.round(value * LBS_TO_KG)
	}
	return Math.round(value)
}

/** Convert height to ft and inches or
 * @param value {number} - Height in cm
 * @param system {'cm' | 'ft'} - Unit system
 * @return {string} – Height in ft and inches or floored cm or empty string
 */
export function convertHeight(value, system = 'cm') {
	if (!value) return ''

	if (system === 'ft') {
		const ft = Math.floor(value / FT_TO_CM)
		const inc = Math.round((value / 30.48 - ft) * 12 * 100) / 100
		return `${ft} ${inc}`
	}

	return String(Math.floor(value))
}

/**
 * @param {number} weight - User weight in kg
 * @param {number} height - User height in cm
 * @param {number} digits - Rounding result
 * @return {number}
 */
export function bmiCalculator(weight, height, digits = 1) {
	if (isNaN(weight) || isNaN(height)) {
		return 0
	}
	let heightInMeters = height / 100
	return round(weight / (heightInMeters * heightInMeters), digits)
}

export function weightByBmi(bmi, height) {
	const heightInMeters = height / 100
	return round(bmi * heightInMeters * heightInMeters, 0)
}

/**
 * Method for generate recommended target weight range.
 * Doc: https://simple-app.atlassian.net/wiki/spaces/GROWTH/pages/157745168/Recommended+target+weight+range
 * @param {number} weight - User weight in kg
 * @param {number} height - User height in cm
 * @param {('kg'|'lbs')} system - Unit System
 * @returns {object|boolean}
 */
export function recommendedWeightRange(weight, height, system = 'lbs') {
	if (!weight || !height) return false

	const systemСoeff = system == 'kg' ? 1 : 2
	const minNomralGoalWeight = 20 * Math.pow(height / 100, 2)
	const isWeightLossPossible = weight >= minNomralGoalWeight + 2 * systemСoeff

	if (!isWeightLossPossible) return false

	const weightLoss20Pct = weight * 0.8
	const weightLoss5Pct = weight * 0.95
	const weightLoss1kg = weight - 1 * systemСoeff

	const minGoalWeight = Math.max(minNomralGoalWeight, weightLoss20Pct)
	const maxGoalWeight =
		minNomralGoalWeight > weightLoss20Pct && Math.abs(weightLoss5Pct - minNomralGoalWeight) < 2 * systemСoeff
			? weightLoss1kg
			: weightLoss5Pct

	return {
		min: convertWeights(minGoalWeight, system),
		max: convertWeights(maxGoalWeight, system),
	}
}

export function legalLinks(language) {
	const domain = 'https://simple.life'
	const availableLanguages = ['en', 'es', 'pt', 'fr', 'de', 'it']
	language = availableLanguages.includes(language) ? language : 'en'

	return {
		tos: `${domain}/tos-${language}.htm`,
		privacy: `${domain}/privacy-${language}.htm`,
		cookiePolicy: `${domain}/cookie-policy-${language}.htm`,
		refund: `${domain}/refund-${language}.htm`,
	}
}

export function convertKgToStAndLbs(kg) {
	const st = Math.floor(kg / ST_TO_KG)
	const lbs = Math.round(kg * LBS_TO_KG - st * 14)
	return {
		st,
		lbs,
	}
}

/**
 * Converts a BMI value to a corresponding weight state string.
 *
 * @param {number} bmi - The Body Mass Index (BMI) value to evaluate.
 * @returns {string} - The weight state corresponding to the given BMI value.
 *                      Possible values are 'underweight', 'normal', 'overweight', 'obesity', or an empty string if the BMI is invalid.
 */
export function bmiNumberToStateString(bmi) {
	if (bmi < BMI_MIN) {
		return 'underweight'
	} else if (bmi >= BMI_MIN && bmi < 25) {
		return 'normal'
	} else if (bmi >= 25 && bmi < 30) {
		return 'overweight'
	} else if (bmi >= 30) {
		return 'obesity'
	}
	return ''
}
