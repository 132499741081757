<template>
	<Portal :to="teleportTo" :disabled="!teleportTo">
		<PaymentMethodObserver target-selector=".paypal-buttons" @ready="$emit('ready')">
			<div class="paypal-container">
				<button v-if="cta" class="btn btn-success btn-submit" :class="classList">
					<span class="spinner-border spinner-border-sm"></span>
					<div class="btn__content">
						<LockIcon />
						{{ cta }}
					</div>
				</button>

				<div class="paypal-integrations" :class="{ transparent: cta, hidden: classList.disabled }">
					<div v-show="integration === 'primer'" class="paypal-integrations__item">
						<PrimerPayPal />
					</div>
					<div v-show="integration === 'braintree'" class="paypal-integrations__item">
						<BraintreePaypal />
					</div>
				</div>
			</div>
		</PaymentMethodObserver>
	</Portal>
</template>

<script>
import { Portal } from 'portal-vue'
import LockIcon from '@/components/icons/LockIcon.vue'
import PaymentMethodObserver from './PaymentMethodObserver'
import PrimerPayPal from './primer/PrimerPayPal.vue'
import BraintreePaypal from './braintree/BraintreePaypal.vue'

export default {
	name: 'PayPalButton',

	components: { Portal, LockIcon, PaymentMethodObserver, PrimerPayPal, BraintreePaypal },

	props: {
		cta: {
			type: String || Boolean,
			default: false,
		},
		classList: {
			type: Object,
			default: () => ({}),
		},
		teleportTo: {
			type: String,
			default: null,
		},
		integration: {
			type: String,
			default: 'primer',
			validator: (value) => ['primer', 'braintree'].includes(value),
		},
	},
}
</script>

<style lang="scss" scoped>
.paypal-container {
	position: relative;
	margin-top: 2rem;
	.btn {
		margin-top: 0;
	}
}

.paypal-integrations {
	&.transparent {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		width: 100%;
		opacity: 0.0001;
		z-index: 1;

		&.hidden {
			display: none;
		}
	}
}

.btn {
	position: relative;

	&-submit {
		width: 100%;
	}

	&__content {
		display: flex;
		align-items: center;
		justify-content: center;
		font-weight: 700;

		svg {
			margin-right: 6px;
			position: relative;
			bottom: 1px;
		}
	}
	.spinner-border {
		position: absolute;
		left: 50%;
		top: 50%;
		margin-top: -0.68rem;
		margin-left: -0.68rem;
		opacity: 0;
	}

	&.loading {
		pointer-events: none;
		.spinner-border {
			opacity: 1;
		}
	}

	&.loading & {
		&__content {
			opacity: 0;
		}
	}

	&.disabled {
		& + {
			.paypal-button {
				display: none;
			}
		}
	}
}
</style>
