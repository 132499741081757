import { initialize, openSession, changeUser, getUser, logCustomEvent, logPurchase, User } from '@braze/web-sdk'

import config from '@/config'
import Sentry from '@/modules/Sentry'
import { ENV_DEV_OR_STAGING } from '@/helpers/environment'
import { round } from '@/helpers/utils'

export default {
	install(Vue, { apiKey, baseUrl, store }) {
		let initialized = false
		const brazeHost = 'https://rest.iad-05.braze.com'

		const getInstance = () => {
			if (!initialized) {
				initialize(apiKey, {
					baseUrl,
					noCookies: true,
				})

				openSession()

				if (store.getters['isAuthenticated'] || store.getters['isOnboarded']) {
					changeUser(store.getters['getUserId'])
				}

				initialized = true
			}

			return { getUser, changeUser, logCustomEvent, logPurchase }
		}

		const addToCustomAttributeArray = (key, value) => {
			getInstance().getUser().addToCustomAttributeArray(key, value)
		}

		const setCustomUserAttribute = (key, value) => {
			getInstance().getUser().setCustomUserAttribute(key, value)
		}

		const makeRequest = (endpoint, params, headers) => {
			return fetch(`${brazeHost}${endpoint}`, {
				body: JSON.stringify(params),
				headers: {
					Authorization: `Bearer ${headers.authorizationKey}`,
					'Content-Type': 'application/json',
				},
				method: 'POST',
			})
		}

		const appboyMethods = {
			changeUser(user_id) {
				getInstance().changeUser(user_id)
			},

			createUserAlias({ email, userId }) {
				getInstance().changeUser(userId)
				getInstance().getUser().setEmail(email)
				getInstance().getUser().addAlias(email)
			},

			/**
			 * Create user in Braze after registration
			 * @param {object} data
			 * @param {'male'|'female'} data.gender
			 * @param {string} data.email
			 * @param {string} data.country
			 * @param {string} data.currency
			 * @param {string} data.funnel
			 * @param {boolean} data.isEmailSubscriptionDisabled
			 * @param {number} data.height
			 * @param {number} data.weight
			 * @param {number} data.targetWeight
			 * @param {'email_submit_short_plans'|'email_submit'} data.emailSubmitEvent
			 */
			logRegistration(data) {
				if (data.gender) {
					getInstance().getUser().setGender(User.Genders[data.gender.toUpperCase()])
				}

				if (data.country) {
					getInstance().getUser().setCountry(data.country)
				}

				getInstance().getUser().setLanguage(window.language.substr(0, 2)) // es-419 is not supported in Braze

				setCustomUserAttribute('currency', data.currency)
				setCustomUserAttribute('funnel', data.funnel)
				getInstance().getUser().setEmail(data.email)
				getInstance().getUser().addAlias(data.email, 'email')

				if (data.isEmailSubscriptionDisabled) {
					getInstance().getUser().setEmailNotificationSubscriptionType(User.NotificationSubscriptionTypes.UNSUBSCRIBED)
				}

				if (data.emailSubmitEvent) {
					getInstance().logCustomEvent(data.emailSubmitEvent, { email: data.email })
				}

				if (
					typeof data.height === 'number' &&
					typeof data.weight === 'number' &&
					typeof data.targetWeight === 'number'
				) {
					setCustomUserAttribute('height', round(data.height, 2))
					setCustomUserAttribute('weight', round(data.weight, 2))
					setCustomUserAttribute('original_weight', round(data.weight, 2))
					setCustomUserAttribute('target_weight', round(data.targetWeight, 2))
				} else {
					Sentry.withScope((scope) => {
						scope.setLevel('warning')

						scope.setExtras({
							height: data.height,
							weight: data.weight,
							targetWeight: data.targetWeight,
						})

						scope.captureMessage('Invalid height/weight data in Braze registration')
					})
				}
			},

			addToCustomAttributeArray(key, value) {
				addToCustomAttributeArray(key, value)
			},

			setCustomUserAttribute(key, value) {
				setCustomUserAttribute(key, value)
			},

			logCustomEvent(event_name, properties = {}) {
				getInstance().logCustomEvent(event_name, properties)
			},

			setFirstName(name) {
				getInstance().getUser().setFirstName(name)
			},

			logPurchase(plan_id, price, currency = 'USD', quantity = 1) {
				getInstance().logPurchase(plan_id, price, currency, quantity)
			},

			canvas(canvas_id, user_id, canvas_entry_properties = {}) {
				return makeRequest(
					'/canvas/trigger/send',
					{
						canvas_id,
						recipients: [
							{
								external_user_id: user_id,
								canvas_entry_properties,
							},
						],
					},
					{
						authorizationKey: config('BrazeCanvasApiKey'),
					},
				)
			},
			/**
			 * Doc: https://www.braze.com/docs/api/endpoints/messaging/schedule_messages/post_schedule_triggered_canvases/
			 */
			canvasShedule({ canvas_id, user_id, canvas_entry_properties = {}, time }) {
				return makeRequest(
					'/canvas/trigger/schedule/create',
					{
						canvas_id,
						recipients: [
							{
								external_user_id: user_id,
								canvas_entry_properties,
							},
						],
						schedule: {
							time,
						},
					},
					{
						authorizationKey: config('BrazeCanvasApiKey'),
					},
				)
			},

			triggerCampaign(campaign_id, user_id, trigger_properties = {}) {
				return fetch('https://rest.iad-05.braze.com/campaigns/trigger/send', {
					body: JSON.stringify({
						campaign_id,
						recipients: [
							{
								external_user_id: user_id,
								trigger_properties,
							},
						],
					}),
					headers: {
						Authorization: `Bearer ${config('BrazeCampaignApiKey')}`,
						'Content-Type': 'application/json',
					},
					method: 'POST',
				})
			},

			/**
			 * Doc: https://www.braze.com/docs/api/endpoints/messaging/schedule_messages/post_schedule_triggered_campaigns
			 */
			campaignShedule({ campaign_id, user_id, canvas_entry_properties = {}, time }) {
				return makeRequest(
					'/campaigns/trigger/schedule/create',
					{
						campaign_id,
						recipients: [
							{
								external_user_id: user_id,
								canvas_entry_properties,
							},
						],
						schedule: {
							time,
						},
					},
					{
						authorizationKey: config('BrazeCampaignApiKey'),
					},
				)
			},
		}

		Vue.prototype.$appboy = appboyMethods

		if (ENV_DEV_OR_STAGING) {
			Object.keys(Vue.prototype.$appboy).forEach((methodName) => {
				Vue.prototype.$appboy[methodName] = new Proxy(appboyMethods[methodName], {
					apply(method, thisObject, argsList) {
						/* eslint-disable no-console */
						console.groupCollapsed(`%cBraze [debug]: %c${method.name}`, 'font-weight: 400', 'color: #ff00cc')
						console.log({ method: method.name, arguments: argsList })
						console.groupEnd()
						/* eslint-enable no-console */

						return method.apply(thisObject, argsList)
					},
				})
			})
		}
	},
}
