const MAP_IDENTS = {
	usd: {
		SIMPLE_PREMIUM: {
			TRIAL_100: 'simple_premium_3m_79_2_usd_7d_trial_1',
			TRIAL_973: 'simple_premium_3m_79_2_usd_7d_trial_9_73',
		},
		UPGRADE: {
			ORIGINAL: 'simple_premium_3m_60_usd',
			WITH_DISCOUNT: 'simple_premium_3m_44_usd',
		},
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_48_usd',
			WITH_DISCOUNT: 'simple_workbook_bundle_36_usd',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_29_99_usd',
			WITH_DISCOUNT: 'simple_workbook_bundle_19_99_usd',
		},
		YOGA_FEMALE_UPSELL_EXPENSIVE: {
			ORIGINAL: 'simple_workbook_bundle_39_99_usd',
			WITH_DISCOUNT: 'simple_workbook_bundle_29_99_usd',
		},
		SEXUAL_HEALTH_UPSELL: {
			ORIGINAL: 'simple_male_health_workbook_29_99_usd',
			WITH_DISCOUNT: 'simple_male_health_workbook_23_99_usd',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_29_99_1w_4_99_usd',
				MONTHLY: 'simple_premium_1m_29_99_1m_17_99_usd',
				QUARTERLY: 'simple_premium_3m_59_99_3m_29_99_usd',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_59_usd',
				WITH_DISCOUNT_3m: 'simple_premium_3m_47_2_usd',

				ORIGINAL_6m: 'simple_premium_6m_79_usd',
				WITH_DISCOUNT_6m: 'simple_premium_6m_63_2_usd',

				ORIGINAL_9m: 'simple_premium_9m_99_usd',
				WITH_DISCOUNT_9m: 'simple_premium_9m_79_2_usd',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_2999_3m_1_usd',
			MONTHLY_1000: 'simple_premium_1m_10_usd',
		},
	},

	eur: {
		SIMPLE_PREMIUM: {
			TRIAL_100: 'simple_premium_3m_79_2_eur_14d_trial_1',
			TRIAL_973: 'simple_premium_3m_79_2_eur_14d_trial_9_73',
		},
		UPGRADE: {
			ORIGINAL: 'simple_premium_3m_60_eur',
			WITH_DISCOUNT: 'simple_premium_3m_44_eur',
		},
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_48_eur',
			WITH_DISCOUNT: 'simple_workbook_bundle_36_eur',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_29_99_eur',
			WITH_DISCOUNT: 'simple_workbook_bundle_19_99_eur',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_29_99_1w_4_99_euro',
				MONTHLY: 'simple_premium_1m_29_99_1m_17_99_eur',
				QUARTERLY: 'simple_premium_3m_59_99_3m_29_99_eur',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_59_eur',
				WITH_DISCOUNT_3m: 'simple_premium_3m_47_2_eur',

				ORIGINAL_6m: 'simple_premium_6m_79_eur',
				WITH_DISCOUNT_6m: 'simple_premium_6m_63_2_eur',

				ORIGINAL_9m: 'simple_premium_9m_99_eur',
				WITH_DISCOUNT_9m: 'simple_premium_9m_79_2_eur',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_2999_3m_1_eur',
			MONTHLY_1000: 'simple_premium_1m_10_eur',
		},
	},

	sek: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_499_sek',
			WITH_DISCOUNT: 'simple_workbook_bundle_379_sek',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_399_sek',
			WITH_DISCOUNT: 'simple_workbook_bundle_199_sek',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_399_1w_59_sek',
				MONTHLY: 'simple_premium_1m_399_1m_199_sek',
				QUARTERLY: 'simple_premium_3m_699_3m_349_sek',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_699_sek',
				WITH_DISCOUNT_3m: 'simple_premium_3m_559_2_sek',

				ORIGINAL_6m: 'simple_premium_6m_939_sek',
				WITH_DISCOUNT_6m: 'simple_premium_6m_751_2_sek',

				ORIGINAL_9m: 'simple_premium_9m_1179_sek',
				WITH_DISCOUNT_9m: 'simple_premium_9m_943_2_sek',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_399_3m_10_sek',
			MONTHLY_1000: 'simple_premium_1m_119_sek',
		},
	},

	brl: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_169_brl',
			WITH_DISCOUNT: 'simple_workbook_bundle_127_brl',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_199_99_brl',
			WITH_DISCOUNT: 'simple_workbook_bundle_99_99_brl',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_199_99_1w_25_99_brl',
				MONTHLY: 'simple_premium_1m_199_99_1m_99_99_brl',
				QUARTERLY: 'simple_premium_3m_299_99_3m_159_99_brl',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_289_brl',
				WITH_DISCOUNT_3m: 'simple_premium_3m_231_2_brl',

				ORIGINAL_6m: 'simple_premium_6m_389_brl',
				WITH_DISCOUNT_6m: 'simple_premium_6m_311_2_brl',

				ORIGINAL_9m: 'simple_premium_9m_489_brl',
				WITH_DISCOUNT_9m: 'simple_premium_9m_391_2_brl',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_199_3m_5_brl',
			MONTHLY_1000: 'simple_premium_1m_49_brl',
		},
	},

	gbp: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_48_gbp',
			WITH_DISCOUNT: 'simple_workbook_bundle_36_gbp',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_29_99_gbp',
			WITH_DISCOUNT: 'simple_workbook_bundle_19_99_gbp',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_29_99_1w_4_99_gbp',
				MONTHLY: 'simple_premium_1m_29_99_1m_17_99_gbp',
				QUARTERLY: 'simple_premium_3m_59_99_3m_29_99_gbp',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_59_gbp',
				WITH_DISCOUNT_3m: 'simple_premium_3m_47_2_gbp',

				ORIGINAL_6m: 'simple_premium_6m_79_gbp',
				WITH_DISCOUNT_6m: 'simple_premium_6m_63_2_gbp',

				ORIGINAL_9m: 'simple_premium_9m_99_gbp',
				WITH_DISCOUNT_9m: 'simple_premium_9m_79_2_gbp',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_2999_3m_1_gbp',
			MONTHLY_1000: 'simple_premium_1m_10_gbp',
		},
	},

	cad: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_48_cad',
			WITH_DISCOUNT: 'simple_workbook_bundle_36_cad',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_49_99_cad',
			WITH_DISCOUNT: 'simple_workbook_bundle_29_99_cad',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_49_99_1w_7_99_cad',
				MONTHLY: 'simple_premium_1m_49_99_1m_26_99_cad',
				QUARTERLY: 'simple_premium_3m_99_99_3m_43_99_cad',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_89_cad',
				WITH_DISCOUNT_3m: 'simple_premium_3m_71_2_cad',

				ORIGINAL_6m: 'simple_premium_6m_119_cad',
				WITH_DISCOUNT_6m: 'simple_premium_6m_95_2_cad',

				ORIGINAL_9m: 'simple_premium_9m_149_cad',
				WITH_DISCOUNT_9m: 'simple_premium_9m_119_2_cad',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_4999_3m_1_cad',
			MONTHLY_1000: 'simple_premium_1m_15_cad',
		},
	},

	aud: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_57_99_aud',
			WITH_DISCOUNT: 'simple_workbook_bundle_42_99_aud',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_49_99_aud',
			WITH_DISCOUNT: 'simple_workbook_bundle_29_99_aud',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_49_99_1w_7_99_aud',
				MONTHLY: 'simple_premium_1m_49_99_1m_26_99_aud',
				QUARTERLY: 'simple_premium_3m_99_99_3m_43_99_aud',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_89_aud',
				WITH_DISCOUNT_3m: 'simple_premium_3m_71_aud',

				ORIGINAL_6m: 'simple_premium_6m_119_aud',
				WITH_DISCOUNT_6m: 'simple_premium_6m_95_aud',

				ORIGINAL_9m: 'simple_premium_9m_149_aud',
				WITH_DISCOUNT_9m: 'simple_premium_9m_119_aud',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_49_99_3m_1_aud',
			MONTHLY_1000: 'simple_premium_1m_15_aud',
		},
	},

	dkk: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_399_dkk',
			WITH_DISCOUNT: 'simple_workbook_bundle_299_dkk',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_259_dkk',
			WITH_DISCOUNT: 'simple_workbook_bundle_159_dkk',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_259_1w_39_dkk',
				MONTHLY: 'simple_premium_1m_259_1m_129_dkk',
				QUARTERLY: 'simple_premium_3m_459_3m_229_dkk',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_439_dkk',
				WITH_DISCOUNT_3m: 'simple_premium_3m_351_2_dkk',

				ORIGINAL_6m: 'simple_premium_6m_589_dkk',
				WITH_DISCOUNT_6m: 'simple_premium_6m_471_2_dkk',

				ORIGINAL_9m: 'simple_premium_9m_739_dkk',
				WITH_DISCOUNT_9m: 'simple_premium_9m_591_2_dkk',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_259_3m_10_dkk',
			MONTHLY_1000: 'simple_premium_1m_79_dkk',
		},
	},

	nok: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_459_nok',
			WITH_DISCOUNT: 'simple_workbook_bundle_339_nok',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_399_nok',
			WITH_DISCOUNT: 'simple_workbook_bundle_199_nok',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_399_1w_59_nok',
				MONTHLY: 'simple_premium_1m_399_1m_199_nok',
				QUARTERLY: 'simple_premium_3m_699_3m_349_nok',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_699_nok',
				WITH_DISCOUNT_3m: 'simple_premium_3m_559_2_nok',

				ORIGINAL_6m: 'simple_premium_6m_939_nok',
				WITH_DISCOUNT_6m: 'simple_premium_6m_751_2_nok',

				ORIGINAL_9m: 'simple_premium_9m_1179_nok',
				WITH_DISCOUNT_9m: 'simple_premium_9m_943_2_nok',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_399_3m_10_nok',
			MONTHLY_1000: 'simple_premium_1m_119_nok',
		},
	},

	mxn: {
		WORKBOOK_BUNDLE: {
			ORIGINAL: 'simple_workbook_bundle_799_mxn',
			WITH_DISCOUNT: 'simple_workbook_bundle_599_mxn',
		},
		ACTIVITY_FEMALE_UPSELL: {
			ORIGINAL: 'simple_workbook_bundle_499_mxn',
			WITH_DISCOUNT: 'simple_workbook_bundle_299_mxn',
		},
		CHEAP_AND_SHORT: {
			SIMPLE_PREMIUM: {
				WITH_TRIAL: 'simple_premium_1m_499_1w_79_mxn',
				MONTHLY: 'simple_premium_1m_499_1m_299_mxn',
				QUARTERLY: 'simple_premium_3m_999_3m_499_mxn',
			},
			UPGRADE: {
				ORIGINAL_3m: 'simple_premium_3m_979_mxn',
				WITH_DISCOUNT_3m: 'simple_premium_3m_783_2_mxn',

				ORIGINAL_6m: 'simple_premium_6m_1319_mxn',
				WITH_DISCOUNT_6m: 'simple_premium_6m_1055_2_mxn',

				ORIGINAL_9m: 'simple_premium_9m_1659_mxn',
				WITH_DISCOUNT_9m: 'simple_premium_9m_1327_2_mxn',
			},
		},
		CANCELLATION: {
			QUARTERLY_2990: 'simple_premium_1m_499_3m_15_mxn',
			MONTHLY_1000: 'simple_premium_1m_169_mex',
		},
	},
}

/**
 * @param {('usd' | 'eur' | 'sek' | 'brl' | 'gbp' | 'cad' | 'aud' | 'dkk' | 'nok' | 'mxn')} currency - One of the supported currencies.
 * @param {('SIMPLE_PREMIUM' | 'UPGRADE' | 'WORKBOOK_BUNDLE' | 'CHEAP_AND_SHORT')} product - The product type.
 * @returns {Object} - The ident object for the specified product in the given currency. If the currency is not found, it defaults to USD.
 */
export function getPaltaProductByCurrency(product, currency = 'usd') {
	if (!MAP_IDENTS[currency]) {
		currency = 'usd'
	}

	return MAP_IDENTS[currency][product]
}
