<template>
	<component :is="componentName" />
</template>

<script>
import { VUE_APP_VERSION } from '@/helpers/environment'
import { mapGetters } from 'vuex'

import { os, version, browserEngine, browserName, browserVersion } from '@/helpers/device'
import OpenTelemetry from '@/modules/OpenTelemetry'
import Jet from '@/modules/Jet'

export default {
	name: 'LandingSwap',
	components: {
		Landing: () => import(/* webpackChunkName: "landing" */ '@/views/landings/Landing.vue'),
		LandingTT: () => import(/* webpackChunkName: "landing-tt" */ '@/views/landings/LandingTT.vue'),
		LandingYT: () => import(/* webpackChunkName: "landing-yt" */ '@/views/landings/LandingYT.vue'),
		LandingMale: () => import(/* webpackChunkName: "landing-male" */ '@/views/landings/LandingMale.vue'),
		LandingFemale: () => import(/* webpackChunkName: "landing-female" */ '@/views/landings/LandingFemale.vue'),
		LandingActivityFemale: () =>
			import(/* webpackChunkName: "landing-activity-female" */ '@/views/landings/LandingActivityFemale.vue'),
		LandingGenderSelection: () =>
			import(/* webpackChunkName: "landing-gender-selection" */ '@/views/landings/LandingGenderSelection.vue'),
		LandingForbes: () => import(/* webpackChunkName: "landing-forbes" */ '@/views/landings/LandingForbes.vue'),
		HealthCoach: () => import(/* webpackChunkName: "your-health-coach" */ '@/views/personal-plan/HealthCoach.vue'),
	},
	computed: {
		...mapGetters({
			language: 'i18n/getLanguage',
			domain: 'location/domain',
			hostname: 'location/hostname',
			getQueryParam: 'location/getQueryParam',
			isForbesAffiliateTraffic: 'location/isForbesAffiliateTraffic',
			onetrustActiveGroups: 'onetrust/getActiveGroups',
		}),
		componentName() {
			if (this.component) {
				return this.component
			}

			if (this.domain.HEALTH_COACH) {
				return 'HealthCoach'
			}

			if (this.isForbesAffiliateTraffic) {
				return 'LandingForbes'
			}

			if (this.IS_ACTIVITY_WELCOME_FOR_GOOGLE_EXP) {
				return 'LandingFemale'
			}

			if (
				this.IS_FEMALE_ACTIVITY_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL ||
				this.IS_WELCOME_AGE_SCREEN_EXP ||
				this.IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP ||
				this.IS_FEMALE_ACTIVITY_SOMATIC_YOGA_OB_FUNNEL ||
				this.IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL
			) {
				return 'LandingActivityFemale'
			} else if (this.IS_MAIN_LOADER_OPTIMIZATION_EXP) {
				return 'LandingGenderSelection'
			} else if (this.IS_FEMALE_FUNNEL_EMAIL_BEFORE_PREDICT) {
				return 'LandingFemale'
			} else if (this.domain.SIMPLEAPP) {
				return this.IS_FEMALE_FUNNEL || this.IS_MALE_FUNNEL ? 'Landing' : 'LandingTT'
			} else if (this.domain.YOUTUBE) {
				return 'LandingYT'
			} else if (this.IS_MALE_FUNNEL) {
				return 'LandingMale'
			} else if (this.IS_FORBES_ON_FIRST_LOADER_EXP) {
				return 'LandingGenderSelection'
			} else {
				return 'Landing'
			}
		},
	},

	created() {
		const utmCampaign = this.getQueryParam('utm_campaign')
			? decodeURIComponent(this.getQueryParam('utm_campaign'))
			: null
		const utmContent = this.getQueryParam('utm_content') ? decodeURIComponent(this.getQueryParam('utm_content')) : null
		const utmTerm = this.getQueryParam('utm_term') ? decodeURIComponent(this.getQueryParam('utm_term')) : null

		this.$analytic
			.setUserProperties({
				Device: { os, version, height: window.innerHeight, width: window.innerWidth },
				browserEngine,
				browserName,
				browserVersion,
				SurveyVersion: VUE_APP_VERSION,
				locale: this.$i18n.locale,
				CookieConsentActiveGroups: this.onetrustActiveGroups,
				hostname: this.hostname,
				from: this.getQueryParam('from'),
				AdgroupId: this.getQueryParam('adgroup_id'),
				AdId: this.getQueryParam('ad_id'),
				CampaignId: this.getQueryParam('campaign_id'),
				UtmGender: this.getQueryParam('utm_gender'),
				UtmMedium: this.getQueryParam('utm_medium'),
				UtmPartnerId: this.getQueryParam('utm_partner_id'),
				UtmSource: this.getQueryParam('utm_source'),
				UtmCampaign: utmCampaign,
				UtmContent: utmContent,
				UtmTerm: utmTerm,
			})
			.then(() => {
				this.$analytic.logEvent('ScreenView', { title: 'OnboardingWelcomeScreen' })
				this.$analytic.logEvent('OnboardingStart', { from: this.getQueryParam('from') })
			})

		Jet.logCoreEvent('OnboardingStart')
	},

	beforeRouteEnter(to, from, next) {
		if (from.path === '/') {
			OpenTelemetry.startSpan('vue-landing-mounted', {
				startTime: window.ot.startTime,
				attributes: { 'span.type': 'vue-landing-mounted' },
			})
		}
		next()
	},
}
</script>
