import { mapGetters } from 'vuex'
import { FUNNELS } from '@/data/funnels'

export default {
	computed: {
		...mapGetters({
			isPaidTraffic: 'location/isPaidTraffic',
			funnelType: 'location/getFunnelType',
			language: 'i18n/getLanguage',
		}),
		IS_DARK_THEME() {
			return ['dark', 'navy'].includes(this.appTheme)
		},
		MAIN_FUNNEL_ROUTE_NAME() {
			return 'survey'
		},
		NOFASTING_FUNNEL_ROUTE_NAME() {
			return this.IS_FEMALE_FUNNEL ? 'female-survey' : 'survey'
		},
		IS_MAIN_FUNNEL() {
			return [FUNNELS.MAIN_UNIVERSAL_FUNNEL.id].includes(this.funnelType.id)
		},
		IS_EMAIL_BEFORE_PREDICT_FUNNEL() {
			return (
				[
					FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id,
					FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
					FUNNELS.ADAPTED_MALE.id,
				].includes(this.funnelType.id) || this.IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP
			)
		},
		IS_MALE_FUNNEL() {
			return [
				FUNNELS.NOFASTING_MALE.id,
				FUNNELS.NOFASTING_ACTIVITY_MALE.id,
				FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.ADAPTED_MALE.id,
			].includes(this.funnelType.id)
		},
		IS_MALE_OB_FUNNEL() {
			return [FUNNELS.MALE_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_FUNNEL() {
			return [
				FUNNELS.NOFASTING_FEMALE.id,
				FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.NOFASTING_ACTIVITY_FEMALE.id,
			].includes(this.funnelType.id)
		},
		IS_FEMALE_OB_FUNNEL() {
			return [FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_OB_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
			].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_OB_FUNNEL_CRO_V1() {
			return FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id === this.funnelType.id
		},
		IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL() {
			return [FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL() {
			return [FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_SOMATIC_YOGA_OB_FUNNEL() {
			return [FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_WALKING_OB_FUNNEL() {
			return [FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_ACTIVITY_ONBOARDING_BUILDER_FUNNEL() {
			return [
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_FUNNEL_ONBOARDING_BUILDER_CRO_V1.id,
				FUNNELS.FEMALE_ACTIVITY_PILATES_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_CHAIR_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_NEW_UI_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_SOMATIC_YOGA_FUNNEL_ONBOARDING_BUILDER.id,
				FUNNELS.FEMALE_ACTIVITY_WALKING_FUNNEL_ONBOARDING_BUILDER.id,
			].includes(this.funnelType.id)
		},
		IS_YOUTUBE_FUNNEL() {
			return [FUNNELS.YOUTUBE.id].includes(this.funnelType.id)
		},
		IS_NOFASTING_FUNNEL() {
			return [FUNNELS.NOFASTING.id, FUNNELS.NOFASTING_MALE.id, FUNNELS.NOFASTING_FEMALE.id].includes(this.funnelType.id)
		},
		IS_NOFASTING_ACTIVITY_MALE() {
			return [FUNNELS.NOFASTING_ACTIVITY_MALE.id].includes(this.funnelType.id)
		},
		IS_NOFASTING_ACTIVITY_FEMALE() {
			return [FUNNELS.NOFASTING_ACTIVITY_FEMALE.id].includes(this.funnelType.id)
		},
		IS_FEMALE_FUNNEL_EMAIL_BEFORE_PREDICT() {
			return [FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		IS_FEMALE_50_PLUS_FUNNEL() {
			const age = this.getPropertyValue('age', true)
			if (age < 50) {
				return false
			}
			return [FUNNELS.FEMALE_FUNNEL_ONBOARDING_BUILDER.id].includes(this.funnelType.id)
		},
		getFunnelTypeStringForGTM() {
			if (this.IS_FEMALE_FUNNEL) {
				return 'female'
			}
			if (this.IS_MALE_FUNNEL) {
				return 'male'
			}
			return 'other'
		},
	},
}
