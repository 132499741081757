import Sentry from '@/modules/Sentry'
import { mapGetters, mapMutations, mapState } from 'vuex'

import { ENV_DEV_OR_STAGING } from '@/helpers/environment'
import Jet from '@/modules/Jet'

export default {
	computed: {
		...mapGetters({
			countryCode: 'getCountryCode',
			isEU: 'getIsEUCountry',
			getQueryParam: 'location/getQueryParam',
			getUserFlags: 'userFlags/getFlags',
			requiredResourcesAreReady: 'status/requiredResourcesAreReady',
			onetrustActiveGroups: 'onetrust/getActiveGroups',
			isOnetrustBannerAutoClosable: 'onetrust/isBannerAutoClosable',
			optInModel: 'onetrust/getOptInModel',
		}),
		...mapState('onetrust', {
			bannerClosed: 'bannerClosed',
		}),

		isOneTrustDisabled() {
			return ENV_DEV_OR_STAGING && this.getQueryParam('onetrust') === false
		},
	},
	watch: {
		$route() {
			// Try to close OneTrust banner for non-EU users with non-blocking consent rules
			if (this.isOnetrustBannerAutoClosable && !this.$route.meta.isLanding) {
				window.OneTrust?.AllowAll?.()
				this.sendOnetrustDoneEvent(false)
			}
		},
	},
	methods: {
		...mapMutations({
			addUserConsent: 'survey/addUserConsent',
			removeUserConsent: 'survey/removeUserConsent',
		}),

		setOnetrustData() {
			const domainData = window.OneTrust.GetDomainData?.() || {}
			const activeGroups = window.OnetrustActiveGroups.split(',').filter(Boolean) || []
			const bannerClosed = window.OneTrust.IsAlertBoxClosed()
			const optInModel = domainData.ConsentModel?.Name === 'opt-in'

			this.$store.commit('onetrust/setActiveGroups', activeGroups)
			this.$store.commit('onetrust/setBannerClosed', bannerClosed)
			this.$store.commit('onetrust/setOptInModel', optInModel)
			this.$store.commit('onetrust/setInited', true)

			if (!bannerClosed) {
				this.sendOnetrustAnalytics(optInModel)
			}

			this.executeConsentRelatedActions()
			this.subscribeToOnetrustEvents(optInModel)

			// Possibility to turn off the OneTrust popup for autotests using `?onetrust=false` query param
			if (this.isOneTrustDisabled && !this.bannerClosed) {
				window.OneTrust.AllowAll()
			}
		},

		sendOnetrustAnalytics(optInModel) {
			if (optInModel) {
				this.$analytic.logEvent('PopupView', { title: 'COOKIE_CONSENT_EXTENDED_POPUP' })
				Jet.logUxEvent('PopupView', { title: 'COOKIE_CONSENT_EXTENDED_POPUP' })
			} else {
				this.$analytic.logEvent('PopupView', { title: 'COOKIE_CONSENT_GENERAL_POPUP' })
				Jet.logUxEvent('PopupView', { title: 'COOKIE_CONSENT_GENERAL_POPUP' })
			}
		},

		executeConsentRelatedActions() {
			Sentry.setUser()
			Sentry.setGeoData()
		},

		subscribeToOnetrustEvents(optInModel) {
			window.OneTrust.OnConsentChanged((event) => {
				this.$store.commit('onetrust/setActiveGroups', event.detail)
				this.$store.commit('onetrust/setBannerClosed', window.OneTrust.IsAlertBoxClosed())

				this.setCookiesRelatedConsents()

				this.$analytic.setUserProperties({
					CookieConsentActiveGroups: this.onetrustActiveGroups,
				})
			})

			document.getElementById('onetrust-consent-sdk').addEventListener(
				'click',
				(e) => {
					const clickTargetId = e.target.id || e.target.className

					if (optInModel) {
						if (clickTargetId === 'onetrust-accept-btn-handler') {
							this.$analytic.logEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_CONFIRM_BUTTON' })
							Jet.logUxEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_CONFIRM_BUTTON' })

							this.sendOnetrustDoneEvent()
						} else if (clickTargetId === 'onetrust-pc-btn-handler') {
							this.$analytic.logEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_BUTTON' })
							Jet.logUxEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_BUTTON' })

							this.$analytic.logEvent('PopupView', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_POPUP' })
							Jet.logUxEvent('PopupView', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_POPUP' })
						} else if (
							clickTargetId === 'accept-recommended-btn-handler' ||
							e.target.classList.contains('ot-pc-refuse-all-handler') ||
							e.target.classList.contains('save-preference-btn-handler')
						) {
							this.$analytic.logEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_CONFIRM_BUTTON' })
							Jet.logUxEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_CONFIRM_BUTTON' })

							this.sendOnetrustDoneEvent()
						} else if (clickTargetId === 'close-pc-btn-handler') {
							this.$analytic.logEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_CLOSE_BUTTON' })
							Jet.logUxEvent('ButtonClick', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_CLOSE_BUTTON' })
						} else if (clickTargetId === 'ot-floating-button__open') {
							this.$analytic.logEvent('ButtonClick', { title: 'COOKIE_CONSENT_WIDGET_BUTTON' })
							Jet.logUxEvent('ButtonClick', { title: 'COOKIE_CONSENT_WIDGET_BUTTON' })

							this.$analytic.logEvent('PopupView', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_POPUP' })
							Jet.logUxEvent('PopupView', { title: 'COOKIE_CONSENT_EXTENDED_SETTINGS_POPUP' })
						}
					} else {
						if (clickTargetId === 'onetrust-accept-btn-handler') {
							this.$analytic.logEvent('ButtonClick', { title: 'COOKIE_CONSENT_GENERAL_CONFIRM_BUTTON' })
							Jet.logUxEvent('ButtonClick', { title: 'COOKIE_CONSENT_GENERAL_CONFIRM_BUTTON' })

							this.sendOnetrustDoneEvent()
						}
					}
				},
				true,
			)
		},

		sendOnetrustDoneEvent(userConsent = true) {
			setTimeout(() => {
				const bannerType = this.optInModel ? 'extended' : 'general'

				this.$analytic.logEvent('CookieConsentDone', {
					banner_type: bannerType,
					user_consent: Boolean(userConsent),
					cookie_type: this.onetrustActiveGroups,
				})

				Jet.logLogicalEvent('CookieConsentDone', {
					bannerType: bannerType,
					userConsent: Boolean(userConsent),
					cookieType: this.onetrustActiveGroups,
				})
			}, 500)
		},

		setCookiesRelatedConsents() {
			if (this.countryCode === 'us') {
				if (this.onetrustActiveGroups.includes('targeting_cookies')) {
					this.addUserConsent('pd_marketing_purposes_us')
				} else {
					this.removeUserConsent('pd_marketing_purposes_us')
				}
			} else if (this.isEU || this.countryCode === 'uk') {
				if (this.onetrustActiveGroups.includes('targeting_cookies')) {
					this.addUserConsent('pd_marketing_purposes_eu-uk')
				} else {
					this.removeUserConsent('pd_marketing_purposes_eu-uk')
				}
			}
		},
	},
	mounted() {
		if (window.OneTrust?.GetDomainData().IsBannerLoaded) {
			this.setOnetrustData()
		} else {
			document.addEventListener('OneTrustReady', this.setOnetrustData, { once: true })
		}
	},
}
