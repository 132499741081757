import { filter } from 'lodash-es'
import { ENV, ENV_DEV_OR_STAGING, VUE_APP_VERSION } from '@/helpers/environment'
import store from '@/store'

/**
 * Filter console warnings except console.warn on /survey/plan_ready
 */
function shouldFilterConsoleWarn(event) {
	return [
		event.level === 'warning',
		event.logger === 'console',
		!event.request?.url.includes('/plan') ||
			event.message?.includes('Pixel') ||
			event.message?.includes('This device does not support Apple Pay') ||
			event.message?.includes('This device is not capable of making Apple Pay payments'),
	].every(Boolean)
}

/**
 * Filter console errors
 */
function shouldFilterConsoleError(event) {
	return [
		event.level === 'error',
		event.logger === 'console',
		/^Base\..*:\s+/.test(event.message), // Filter Base.*:* errors
	].every(Boolean)
}

const BREADCRUMBS_URLS_BLACKLIST = [
	'https://ct.pinterest.com',
	'https://cdn-ukwest.onetrust.com',
	'https://analytics.google.com',
	'https://tr.snapchat.com',
]

function shouldFilterNetworkErrors(event, error) {
	const errorName = error?.originalException?.name
	const errorMessage = error?.originalException?.message

	if (
		[
			errorName === 'TypeError' && ['Failed to fetch', 'Load failed'].includes(errorMessage),
			errorName === 'AxiosError' && ['Network Error'].includes(errorMessage),
		].some(Boolean)
	) {
		const errorBreadcrumbs = filter(event.breadcrumbs, { category: 'fetch', level: 'error' })

		return errorBreadcrumbs.every((breadcrumb) =>
			BREADCRUMBS_URLS_BLACKLIST.some((url) => breadcrumb.data?.url?.startsWith(url)),
		)
	}

	return false
}

class Sentry {
	constructor() {
		this._instance = null

		this._isInited = new Promise((resolve) => {
			this._isInitedResolve = resolve
		})

		this.#init()
	}

	async #init() {
		const {
			init,
			captureException,
			captureMessage,
			setTag,
			setTags,
			setUser,
			withScope,
			captureConsoleIntegration,
			extraErrorDataIntegration,
		} = await import(/* webpackChunkName: "sentry" */ '@sentry/browser')

		this._instance = {
			captureException,
			captureMessage,
			setTag,
			setTags,
			setUser,
			withScope,
		}

		init({
			dsn: 'https://eaf1a8decc454276894d6dd63066bb96@o1383508.ingest.sentry.io/4505087656919044',
			autoSessionTracking: false,
			environment: ENV,
			release: VUE_APP_VERSION,
			integrations: [
				extraErrorDataIntegration({}),
				captureConsoleIntegration({
					levels: ['error', 'warn'],
				}),
			],
			ignoreErrors: [
				'TypeError: cancelled',
				'AxiosError: Request aborted',
				'getBattery',
				'Object Not Found Matching Id',
				'AbortError',
				"ReferenceError: Can't find variable: OffscreenCanvas", // GTM axon
				"TypeError: null is not an object (evaluating 'context.getExtension')", // GTM axon
				"undefined is not an object (evaluating 'G.bannerGroup.html')",
				"undefined is not an object (evaluating 't.Domain')",
				"undefined is not an object (evaluating 't.DomainData'')",
			],
			beforeSend: (event, error) => {
				if (shouldFilterConsoleWarn(event)) {
					return null
				}

				if (shouldFilterConsoleError(event)) {
					return null
				}

				if (shouldFilterNetworkErrors(event, error)) {
					return null
				}

				if (ENV_DEV_OR_STAGING) {
					let message = event.transaction || event.message || error?.originalException?.message

					if (message && message.length > 60) {
						message = `${message.substr(0, 60)}…`
					}

					/* eslint-disable no-console */
					console.groupCollapsed(`%cSentry Logger [${event.level}]: ${message}`, 'color: #f00')
					console.log(event)
					console.dir(error?.originalException)
					console.groupEnd()
					/* eslint-enable no-console */
				}

				return event
			},
		})

		this._isInitedResolve()
	}

	/**
	 * @param {*} exception
	 * @returns {Promise<void>}
	 */
	async captureException(exception) {
		await this._isInited

		this._instance.captureException(exception)
	}

	/**
	 * @param {String} message
	 * @returns {Promise<void>}
	 */
	async captureMessage(message) {
		await this._isInited

		this._instance.captureMessage(message)
	}

	/**
	 * @param {Function} callback
	 * @returns {Promise<void>}
	 */
	async withScope(callback) {
		await this._isInited

		this._instance.withScope(callback)
	}

	/**
	 * @param {String} tagName
	 * @param {any} value
	 * @returns {Promise<void>}
	 */
	async setTag(tagName, value) {
		await this._isInited

		this._instance.setTag(tagName, value)
	}

	/**
	 * @param {Object} tags
	 * @returns {Promise<void>}
	 */
	async setTags(tags) {
		await this._isInited

		this._instance.setTags(tags)
	}

	/**
	 * @returns {Promise<void>}
	 */
	async setUser() {
		await this._isInited

		if (!store.getters.getUserId || !store.getters['onetrust/isActiveConsentByName']('functional_cookies')) {
			return
		}

		this._instance.setUser({ id: store.getters.getUserId })
	}

	/**
	 *
	 * @returns {Promise<void>}
	 */
	async setGeoData() {
		await this._isInited

		if (!store.getters['onetrust/isActiveConsentByName']('functional_cookies')) {
			return
		}

		this._instance.setTags({
			countryCode: store.getters.getCountryCodeUppercase,
			currencyCode: store.getters.getCurrencyUppercase,
			language: window.language,
		})
	}
}

/** @type Sentry */
const sentry = new Sentry()

export default sentry
