/**
 * Method which build correct download link, based on url's utm params
 *
 * @param {string} baseDownloadURL - URL for downloading
 * @param {object} queryParams - saved query params
 * @param {string} loginCode - generated login code
 * @param {*} subscription - applicable subscriptions
 */
export function convertUtmUrlToDownloadLink(baseDownloadURL, queryParams, loginCode, subscription) {
	const downloadLink = new URL(baseDownloadURL)

	if (
		queryParams['utm_source'] &&
		queryParams['utm_medium'] &&
		['fb', 'google'].includes(queryParams['utm_source'].toLowerCase())
	) {
		const pid = queryParams['utm_source'].toLowerCase() === 'fb' ? 'facebook ads' : 'google_lp'
		downloadLink.searchParams.append('pid', pid)
	}

	if (queryParams['utm_campaign']) {
		downloadLink.searchParams.append('c', queryParams['utm_campaign'])
	}

	if (queryParams['campaign_id']) {
		downloadLink.searchParams.append('af_c_id', queryParams['campaign_id'])
	}

	if (queryParams['utm_content']) {
		downloadLink.searchParams.append('af_adset', queryParams['utm_content'])
	}

	if (queryParams['adgroup_id']) {
		downloadLink.searchParams.append('af_adset_id', queryParams['adgroup_id'])
	}

	if (queryParams['gclid']) {
		downloadLink.searchParams.append('clickid', queryParams['gclid'])
	}

	if (queryParams['utm_term']) {
		downloadLink.searchParams.append('af_keywords', queryParams['utm_term'])
	}

	if (queryParams['download_source']) {
		downloadLink.searchParams.append('download_source', queryParams['download_source'])
	}

	if (loginCode) {
		downloadLink.searchParams.append('loginCode', loginCode)
		downloadLink.searchParams.append('deep_link_value', JSON.stringify({ loginCode }))

		downloadLink.searchParams.append('is_retargeting', 'true')
		downloadLink.searchParams.append('pid', 'web')
	}

	if (subscription) {
		downloadLink.searchParams.append('af_sub1', 'purchase')
		downloadLink.searchParams.append('af_sub2', 'pay')
		downloadLink.searchParams.append('af_sub3', 'One-Time Week Price Down')
		downloadLink.searchParams.append('af_sub4', '39.99')
	}

	if (queryParams.email) {
		downloadLink.searchParams.append('email', queryParams.email)
		const deepLinkValue = downloadLink.searchParams.has('deep_link_value')
			? JSON.parse(downloadLink.searchParams.get('deep_link_value'))
			: {}
		deepLinkValue.email = queryParams.email
		downloadLink.searchParams.set('deep_link_value', JSON.stringify(deepLinkValue))
		downloadLink.searchParams.append('is_retargeting', 'true')
	}

	return downloadLink
}

export function getHostnameFromURL(url) {
	let hostname = ''

	if (typeof url !== 'string' || !url.length) {
		return hostname
	}

	try {
		hostname = new URL(url).hostname
	} catch (e) {}

	return hostname
}

export function getQueryParamsFromUrl(url) {
	let queryParams = {}

	try {
		const urlObject = new URL(url)
		const searchParamsEntries = urlObject.searchParams.entries()

		queryParams = Object.fromEntries(searchParamsEntries)
	} catch (e) {}

	return queryParams
}
