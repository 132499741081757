import config from '@/config'
import store from '@/store'

class Auth0 {
	constructor(options) {
		this._webAuth = null

		this._isInited = new Promise((resolve) => {
			this._isInitedResolve = resolve
		})

		this.#init(options)
	}

	async #init(options) {
		const { default: auth0 } = await import(/* webpackChunkName: "auth0" */ 'auth0-js')

		this._webAuth = new auth0.WebAuth({
			responseType: 'token id_token',
			scope: 'openid profile email offline_access',
			redirectUri: window.location.origin + '/signin-auth0',
			...options,
		})

		this._isInitedResolve()
	}

	#clearAuth0PersistedJWT() {
		store.commit('setAuth0Credentials', {
			accessToken: null,
			idToken: null,
			expiresAt: null,
		})
	}

	#setAuth0PersistedJWT(authResult) {
		let expiresAt = authResult.expiresIn * 1000 + new Date().getTime()
		store.commit('setAuth0Credentials', {
			accessToken: authResult.accessToken,
			idToken: authResult.idToken,
			expiresAt: expiresAt,
		})
	}

	#getAuth0PersistedJWT() {
		return {
			accessToken: store.getters.getAuth0Credentials.accessToken,
			idToken: store.getters.getAuth0Credentials.idToken,
			expiresAt: store.getters.getAuth0Credentials.expiresAt,
		}
	}

	isAuthenticated() {
		const { expiresAt, accessToken, idToken } = this.#getAuth0PersistedJWT()
		if (!accessToken || !idToken || !expiresAt) {
			return false
		} else {
			return new Date().getTime() < expiresAt
		}
	}

	getUser() {
		// TODO: Implement after PoC stage
		return this.#getAuth0PersistedJWT()
	}

	async refreshSession() {
		await this._isInited

		return new Promise((resolve, reject) => {
			this._webAuth.checkSession({}, (err, authResult) => {
				if (err) {
					reject(err)
				} else {
					this.#setAuth0PersistedJWT(authResult)
					resolve(this.#getAuth0PersistedJWT())
				}
			})
		})
	}

	async loginWithPasswordless(email, options = {}) {
		await this._isInited

		return new Promise((resolve, reject) => {
			this._webAuth.passwordlessStart(
				{
					connection: 'email',
					send: 'code',
					email: email,
					...options,
				},
				(err, res) => {
					if (err) {
						reject(err)
					} else {
						resolve(res)
					}
				},
			)
		})
	}

	async authorizeWithPasswordless(code, email, options = {}) {
		await this._isInited

		return new Promise((resolve, reject) => {
			this._webAuth.passwordlessLogin(
				{
					connection: 'email',
					verificationCode: code,
					email: email,
					anonymous_token: store.state.auth.token,
					audience: config('Auth0AudienceDomain'),
					...options,
				},
				(err, res) => {
					if (err) {
						reject(err)
					} else {
						this.#setAuth0PersistedJWT(res)
						resolve(this.#getAuth0PersistedJWT())
					}
				},
			)
		})
	}

	async checkUrlHashByAuthParams() {
		await this._isInited

		return new Promise((resolve, reject) => {
			this._webAuth.parseHash((err, authResult) => {
				if (authResult && authResult.accessToken && authResult.idToken) {
					this.#setAuth0PersistedJWT(authResult)
					const url = new URL(window.location.href)
					url.search = ''
					window.history.replaceState({}, document.title, url.pathname)
					this.#getAuth0PersistedJWT()
					resolve(this.#getAuth0PersistedJWT())
				} else if (err) {
					console.error(err)
					reject(err)
				}
				resolve(null)
			})
		})
	}

	async logout() {
		await this._isInited

		this.#clearAuth0PersistedJWT()
		this._webAuth.logout({
			returnTo: window.location.origin,
		})
	}

	async auth0RouterGuard(to, from, next) {
		if (to.matched.some((record) => record.meta.requiresAuth0)) {
			await this.checkUrlHashByAuthParams()
			if (!this.isAuthenticated()) {
				next({ name: 'signin-auth0' })
			} else {
				next()
			}
		} else {
			next()
		}
	}
}

const auth0Instance = new Auth0({
	domain: config('Auth0Domain'),
	clientID: config('Auth0ClientId'),
	audience: config('Auth0AudienceDomain'),
})

export default auth0Instance
