<template>
	<div class="palta-checkout">
		<div id="payment-container">
			<div class="palta-checkout__item" v-show="canShowPaymentMethod('card')">
				<CardForm
					:integration="integration"
					:cta="mergedCardProps.cta"
					:button-class-list="buttonClassList"
					:rendered="isRendered"
					:teleport-to="mergedCardProps.teleportTo"
					@ready="onPaymentMethodReady('card')"
					@submit="onSubmitCardForm"
				/>
			</div>
			<div class="palta-checkout__item" v-show="canShowPaymentMethod('paypal')">
				<PayPalButton
					:integration="integration"
					:cta="mergedPaypalProps.cta"
					:class-list="buttonClassList"
					:teleport-to="mergedPaypalProps.teleportTo"
					@ready="onPaymentMethodReady('paypal')"
				/>
			</div>
			<div class="palta-checkout__item" v-show="canShowPaymentMethod('apple')">
				<ApplePayButton
					:class-list="buttonClassList"
					:teleport-to="mergedApplePayProps.teleportTo"
					@ready="onPaymentMethodReady('apple_pay')"
				/>
			</div>
		</div>

		<!-- FIXME: Our implementation does not require these containers, but the SDK relies on it. -->
		<div class="hidden-containers">
			<div id="braintree-checkout-container"></div>
			<div id="primer-checkout-form"></div>
			<div id="payment-status"></div>
			<div id="error-payment-status"></div>
		</div>
	</div>
</template>

<script>
import { createPaymentClient } from '@palta-brain/web-sdk/dist/cjs/src/CreatePaymentClient'
import uuid from 'uuid-random'
import { mapGetters } from 'vuex'

import { env } from '@/helpers/environment'
import config from '@/config'
import { PAYMENT_METHODS } from '../helpers/constants'
import {
	getDefaultPaypalOptions,
	getDefaultApplePayOptions,
	getDefaultCardOptions,
	getDefaultBraintreeCardOptions,
} from '../helpers/checkoutOptions'
import { paltaApi } from '../api'
import { mergeDeep } from '@/helpers/utils'

import CardForm from './CardForm.vue'
import PayPalButton from './PayPalButton.vue'
import ApplePayButton from './ApplePayButton.vue'

export default {
	name: 'PaltaCheckout',

	components: { CardForm, PayPalButton, ApplePayButton },

	props: {
		ident: {
			type: String,
			required: true,
		},

		displayedPaymentMethods: {
			type: Array,
			default: () => ['card', 'paypal'],
			validator: (value) => {
				return Object.keys(PAYMENT_METHODS).map((key) => value.includes(key))
			},
		},

		availablePaymentMethods: {
			type: Array,
			default: () => ['card', 'paypal', 'apple'],
		},

		email: {
			type: String,
			required: true,
		},

		locale: {
			type: String,
			default: 'en',
		},

		cardOptions: {
			type: Object,
			default: () => ({}),
		},

		paypalOptions: {
			type: Object,
			default: () => ({}),
		},

		applePayOptions: {
			type: Object,
			default: () => ({}),
		},
		theme: {
			type: String,
			default: 'light',
			validator: (value) => {
				return ['light', 'dark', 'navy'].includes(value)
			},
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		discountAmount: {
			type: [Number, null],
			default: null,
		},
		promocode: {
			type: [String, null],
			default: null,
		},
	},

	data() {
		return {
			isRendered: false,
			buttonStates: {
				isVisible: false,
				isDisabled: false,
				isLoading: false,
			},
			client: null,
			checkoutForm: null,
			pendingCheckout: null,
			lastUsedPaymentMethod: null,
			hasPendingSessionUpdate: false,
			integration: null,
		}
	},

	computed: {
		...mapGetters({
			countryCode: 'getCountryCode',
			userId: 'getUserId',
			currency: 'getCurrencyUppercase',
		}),

		mergedCardProps() {
			const defaultProps = {
				cta: this.$t('common.controls.continue'),
				options: {
					...getDefaultCardOptions({
						theme: this.theme,
					}),
				},
			}
			return mergeDeep(defaultProps, this.cardOptions)
		},

		mergedPaypalProps() {
			const defaultProps = {
				cta: this.$t('common.controls.purchase_button'),
				options: {
					...getDefaultPaypalOptions({
						theme: this.theme,
					}),
					onClick: () => {
						this.emitSubmit('paypal')
					},
				},
			}
			return mergeDeep(defaultProps, this.paypalOptions)
		},

		mergedApplePayProps() {
			const defaultProps = {
				options: {
					...getDefaultApplePayOptions({
						theme: this.theme,
					}),
				},
			}
			return mergeDeep(defaultProps, this.applePayOptions)
		},

		buttonClassList() {
			return {
				disabled: this.buttonStates.isDisabled || !this.isRendered || this.disabled,
				loading: this.buttonStates.isLoading || !this.isRendered,
			}
		},

		paymentMethods() {
			return Object.entries(PAYMENT_METHODS).reduce((filteredMethods, [key, value]) => {
				if (this.availablePaymentMethods.includes(key)) {
					filteredMethods[key] = value
				}
				return filteredMethods
			}, {})
		},

		/**
		 * For more information and available options, refer to the Universal Checkout documentation at:
		 * https://primer.io/docs/payments/universal-checkout/drop-in/customize-checkout/web
		 *
		 * Types: https://www.npmjs.com/package/@primer-io/checkout-web?activeTab=code
		 */
		universalCheckoutOptions() {
			return {
				container: `#checkout-form`,
				locale: this.locale,
				...this.mergedCardProps.options,
				paypal: this.mergedPaypalProps.options,
				applePay: this.mergedApplePayProps.options,
				submitButton: {
					useBuiltInButton: false,
					onVisible: (isVisible) => {
						this.buttonStates.isVisible = isVisible
					},

					onDisable: (isDisabled) => {
						this.buttonStates.isDisabled = isDisabled
					},

					onLoading: (isLoading) => {
						this.buttonStates.isLoading = isLoading
					},
				},
			}
		},

		braintreeOptions() {
			return {
				styles: {
					...getDefaultBraintreeCardOptions({
						theme: this.theme,
					}),
				},
				createPayment: () => {
					this.emitStatusComplete()
				},
				// FIXME: Without these callbacks, the SDK throws an error.
				createOrder: () => {},
				onCancel: () => {},
				threeDSecureOnLookupComplete: () => {},
				threeDSecureValidated: () => {},
				onBeforePaymentCreate: () => {},
				applePayOptions: {
					merchantCapabilities: ['supports3DS'],
					supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
					total: {
						label: 'Demo (Card is not charged)',
					},
				},
			}
		},

		providerRegion() {
			return ['us', 'ca', 'br', 'mx'].includes(this.countryCode) ? 'us' : 'eu'
		},
	},

	watch: {
		ident() {
			this.updateCheckout({ force: true })
		},
		discountAmount() {
			this.updateCheckout()
		},
	},

	methods: {
		getPaymentClientSettings(orderId) {
			return {
				ident: this.ident,
				countryCode: this.countryCode?.toUpperCase() ?? 'US',
				platformCode: 'desktop_web',
				orderId,
				customer: {
					id: {
						type: 'merchant-uuid',
						value: this.userId,
					},
					emailAddress: this.email,
				},
				metadata: {
					provider_region: this.providerRegion,
				},
			}
		},

		canShowPaymentMethod(paymentMethod) {
			return (
				this.displayedPaymentMethods.includes(paymentMethod) && this.availablePaymentMethods.includes(paymentMethod)
			)
		},

		createClient() {
			const settings = {
				apiEndpoint: config('PaltaApiUrl'),
				apiKey: config('PaltaApiKey'),
				email: this.email,
				metadata: {
					...this.paymentMethods,
					provider_region: this.providerRegion,
				},
				onError: (error, description) => {
					this.$emit('error', {
						error,
						description,
					})
				},
				onPaymentStatusChange: this.onPaymentStatusChange,
			}

			this.client = createPaymentClient(settings)
		},

		onPaymentStatusChange(status, paymentMethod) {
			if (paymentMethod) {
				this.lastUsedPaymentMethod = paymentMethod
			}
			switch (status) {
				case 'COMPLETE':
					this.emitStatusComplete()
					break
				case 'PENDING':
					this.$emit('pending')
					break
				default:
					break
			}
		},

		async generateOrderId() {
			const defaultOrderId = uuid()

			if (!this.discountAmount) {
				return defaultOrderId
			}

			if (this.discountAmount <= 0) {
				this.$emit('error', new Error('Promocode discount is negative or zero.'))
				return defaultOrderId
			}

			try {
				const response = await paltaApi.getOrderId({
					userId: this.userId,
					amount: this.discountAmount,
					currency: this.currency,
					promocode: this.promocode,
				})

				return response.order_id
			} catch (e) {
				this.$emit('error', e)

				return defaultOrderId
			}
		},

		emitStatusComplete() {
			this.$emit('loading', true)

			this.$store
				.dispatch('loadPaltaSubscription', {
					ident: this.ident,
				})
				.then((payload) => {
					const { subscription = {} } = payload
					// FIXME: Temporary event for debug
					this.$analytic.logEvent('Api - Palta', {
						...subscription,
					})
					this.$emit('complete', {
						...subscription,
						payment_method: this.lastUsedPaymentMethod,
					})
				})
				.catch((e) => {
					this.$emit('error', e)
				})
		},

		emitSubmit(provider) {
			this.$emit('submit', {
				provider,
			})
		},

		onPaymentMethodReady(paymentMethod) {
			this.$emit('ready', { paymentMethod })
		},

		async renderCheckout() {
			const orderId = await this.generateOrderId()
			const settings = {
				...this.getPaymentClientSettings(orderId),
			}

			this.client
				.showPaymentForm({
					settings,
					primerOption: this.universalCheckoutOptions,
					braintreeOption: this.braintreeOptions,
					paymentWrapperSelector: 'payment-container',
					primerCheckoutFormSelector: 'primer-checkout-form',
					braintreeCheckoutFormSelector: 'braintree-checkout-container',
					payPalEnv: env('PAYPAL_ENVIRONMENT'),
				})
				.then((checkoutForm) => {
					this.integration = this.getIntegrationByCheckoutInstance(checkoutForm)
					this.checkoutForm = checkoutForm
					this.$store.dispatch('createPaymentCustomer')
				})
				.catch(this.handleError)
				.finally(this.handleFinally)
		},

		async updateCheckout(options) {
			const { force = false } = options ?? {}

			if (!this.isRendered) {
				if (!force) return
				this.hasPendingSessionUpdate = true
				return
			}
			this.isRendered = false
			const orderId = await this.generateOrderId()
			const settings = {
				...this.getPaymentClientSettings(orderId),
			}
			this.client
				.updatePaymentForm(settings)
				.then(() => {
					this.$emit('update')
				})
				.catch(this.handleError)
				.finally(this.handleFinally)
		},

		handleError(error) {
			this.$emit('error', error)
		},

		handleFinally() {
			this.isRendered = true

			if (this.hasPendingSessionUpdate) {
				this.hasPendingSessionUpdate = false
				this.updateCheckout({ force: true })
			}
		},

		onSubmitCardForm() {
			if (this.integration === 'primer') {
				this.checkoutForm.submit()
			}

			this.emitSubmit('card')
		},

		getIntegrationByCheckoutInstance(checkoutInstance) {
			if (
				checkoutInstance?.constructor?.name === 'Client' ||
				typeof checkoutInstance?.getConfiguration === 'function'
			) {
				return 'braintree'
			}

			return 'primer'
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.createClient()
			this.renderCheckout()
		})
	},
}
</script>

<style lang="scss" scoped>
.palta-checkout {
	position: relative;
	display: flex;
	flex-direction: column;
	gap: 30px;
}

.hidden-containers {
	display: none;
}
</style>
