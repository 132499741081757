import axios from 'axios'
import config from '@/config'
import { paymentApiAxiosInstance } from '@/helpers/request'

import { FEATURES } from '../helpers/constants'

const instance = axios.create({
	baseURL: config('PaltaApiUrl'),
	headers: {
		'x-api-key': config('PaltaApiKey'),
		'Content-Type': 'application/json;charset=utf-8',
	},
})

export const paltaApi = {
	/**
	 * Existing price points: https://developer.paltabrain.com/payments-v2/price-configurator/get-price-points
	 * Api doc: https://api.payments.paltabrain.com/docs/merchants#/showcase/showcase_get_price_points_showcase_get_price_points_post
	 * @param {String} customerId
	 * @param {Object} params
	 * @returns {Promise}
	 */
	fetchPricePoints(customerId, params) {
		const { ident = [], feature = FEATURES.SIMPLE_PREMIUM } = params
		return instance
			.post('/showcase/get-price-points', {
				platformCode: 'desktop_web',
				customerId: {
					type: 'merchant-uuid',
					value: customerId,
				},
				ident,
				availabilityRules: true,
				requestContext: {
					feature,
				},
			})
			.then((response) => {
				return response.data.pricePoints
			})
	},

	fetchSubscriptions(customerId, ids = []) {
		return instance
			.post('/subscriptions-tracker/get-subscriptions', {
				customerId: {
					type: 'merchant-uuid',
					value: customerId,
				},
				onlyIds: ids,
			})
			.then((response) => {
				return response.data
			})
	},

	fetchFeatures(customerId) {
		return instance
			.post('/feature-provisioner/get-features', {
				customerId: {
					type: 'merchant-uuid',
					value: customerId,
				},
			})
			.then((response) => {
				return response.data.features
			})
	},

	/**
	 * Makes a purchase request.
	 *
	 * @param {string} userId - The ID of the user.
	 * @param {string} ident - The ident for the purchase.
	 * @param {'UNSCHEDULED'} paymentType - The type of payment being used.
	 * @returns {Promise} A promise that resolves to the purchase response.
	 */
	purchase({ userId, ident, paymentType }) {
		return paymentApiAxiosInstance.post('v1/api/payment/paltabrain', {
			user_id: userId,
			ident,
			payment_type: paymentType,
		})
	},

	/**
	 * Upgrades the user's subscription.
	 *
	 * @param {string} userId - The ID of the user.
	 * @param {string} ident - The ident of the new plan.
	 * @param {'UNSCHEDULED'} paymentType - The payment type.
	 * @param {boolean} [excludeTrialAmount=false] - Whether to exclude the purchased trial amount.
	 * @returns {Promise} - A promise that resolves to the response data.
	 */
	upgrade({ userId, ident, paymentType, excludeTrialAmount = false }) {
		return paymentApiAxiosInstance
			.post('v1/api/subscription/paltabrain/upgrade', {
				user_id: userId,
				ident,
				payment_type: paymentType,
				exclude_trial_amount: excludeTrialAmount,
			})
			.then((response) => {
				return response.data
			})
	},

	/**
	 * Migrates a deferred subscription for a user.
	 *
	 * @param {string} userId - The ID of the user.
	 * @param {string} subscriptionId - The Palta subscription ID.
	 * @param {string} plan - The ident of the new plan.
	 * @param {'cancel-offer'|'upgrade'} subscriptionType - The type of the subscription.
	 * @returns {Promise} - A promise that resolves to the response data.
	 */
	migrateDeferred({ userId, subscriptionId, ident, subscriptionType }) {
		return paymentApiAxiosInstance
			.post('/v1/api/subscription/paltabrain/migrate-deferred', {
				user_id: userId,
				subscription_id: subscriptionId,
				plan: ident,
				subscription_type: subscriptionType,
			})
			.then((response) => {
				return response.data
			})
	},

	getOrderId({ userId, amount, currency, promocode }) {
		return paymentApiAxiosInstance
			.post('/v1/api/subscription/paltabrain/discount', {
				user_id: userId,
				amount,
				currency,
				promocode,
			})
			.then((response) => {
				return response.data
			})
	},

	/**
	 * Charges a fee for commitment subscription.
	 *
	 * @param {string} amount - The amount to charge.
	 * @param {string} subscriptionId - The ID of the subscription.
	 * @returns {Promise} - A promise that resolves to the response data.
	 */
	chargeFee({ subscriptionId, amount }) {
		return paymentApiAxiosInstance
			.post('/v1/api/subscription/paltabrain/charge-fee', {
				subscription_id: subscriptionId,
				amount,
			})
			.then((response) => {
				return response.data
			})
	},
}
