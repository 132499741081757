import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_3M_59_99_3M_23_99',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_3m_59_99_3m_23_99_usd',
		},
	},
	trial: {
		days: 90,
		priceMap: {
			USD: 23.99,
		},
		originalPriceMap: {
			USD: 59.99,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 59.99,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.90_days.into_period_text'), // 'first quarter'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.90_days.sub_period_text'), // 'quarterly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.90_days.into_period_text'), // 'three months'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '3-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.90_days.sub_period_text'), // '3-month'
		pricePerDayWithoutDiscount: 0.33,
	}),
}
