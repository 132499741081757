<template>
	<div class="funnel-container">
		<slot></slot>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import Sentry from '@/modules/Sentry'
import Jet from '@/modules/Jet'
import { defineFunnelByAppArea, defineFunnelByParams } from '@/helpers/funnels'

export default {
	name: 'FunnelContainer',

	watch: {
		activeFunnelType: {
			async handler(newValue, oldValue) {
				if (newValue.funnelType.id === oldValue?.funnelType?.id) {
					return
				}
				if (this.$route.meta.isLanding && this.$route.query.utm_source) {
					await this.$store.dispatch('location/storeInitialPath', { force: true })
				}
				this.storeFunnel(newValue)
			},
			immediate: true,
		},
	},

	computed: {
		...mapGetters({
			domain: 'location/domain',
			funnelType: 'location/getFunnelType',
			getQueryParam: 'location/getQueryParam',
			property: 'survey/getPropertyValue',
			language: 'i18n/getLanguage',
			countryCode: 'getCountryCode',
			initialPath: 'location/getInitialPath',
			hostname: 'location/hostname',
		}),

		/**
		 * @returns {{funnelType: Object, paywallRoute: string}}
		 */
		activeFunnelType() {
			const queryAppArea = this.getQueryParam('area')
			if (queryAppArea) {
				return defineFunnelByAppArea(queryAppArea, this.countryCode, this.fireSentryError)
			} else {
				const funnel = defineFunnelByParams(
					{
						country: this.countryCode,
						domain: this.hostname,
						prefix: this.initialPath,
					},
					(experimentId, isInitialExposure = false) =>
						isInitialExposure ? this.$absmartly.treatment(experimentId) : this.$absmartly.assign(experimentId),
					this.fireSentryError,
				)

				return funnel
			}
		},
	},

	methods: {
		storeFunnel({ funnelType, paywallRoute, userTags, userFlags }) {
			this.$store.commit('location/setFunnelType', { ...funnelType, userTags, userFlags })
			this.$store.commit('location/setPaywallRoute', paywallRoute)

			this.$analytic.setUserProperties({
				FunnelId: funnelType.id,
				PaywallRoute: paywallRoute,
			})

			Jet.setOnboarding({
				onboardingFunnelId: funnelType.id,
				onboardingFunnelType: funnelType.slug,
				onboardingUrlPath: this.$route.path,
			})

			Sentry.setTags({
				funnelId: funnelType.id,
				funnelType: funnelType.slug,
			})
		},
		fireSentryError(message, extras) {
			Sentry.withScope((scope) => {
				scope.setTags({
					funnelTypeError: true,
				})
				scope.setExtras(extras)
				scope.captureMessage(message)
			})
		},
	},
}
</script>

<style lang="scss" scoped>
.funnel-container {
	min-height: calc(var(--vh, 1vh) * 100);
}
</style>
