export default {
	id: 'SIMPLE_UPGRADE_6M_77_95',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_6m_77_95_usd',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 6,
		priceMap: {
			USD: 77.95,
		},
	},
	options: () => ({
		// FIXME: will be refactored before rollout
		currentSubscriptionRateText: (context) => {
			return `You received a discounted rate of ${context.trialPrice} for your first month. After that, your subscription is set to renew at the standard $29.99/mo.`
		},
		upgradeOfferText: 'Upgrade to our 6-month plan now to lock-in your $12.99/mo rate and continue saving!',
		barChartTooltip: 'Next month',
		renewalPeriod: 'monthly',
		upgradePlanPeriod: '6-month',
		upgradeRate: '/mo',
		currentPriceLabel: 'Current monthly price',
		offerPriceLabel: 'Monthly price with offer',
		totalLabel: 'Total for 6 months',
		pricingTableTitle: 'Upgrade to 6 months to claim offer',
	}),
}
