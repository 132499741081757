export default {
	id: 'SIMPLE_PREMIUM_3M_66_65_3M_25_99',
	providers: {
		paltaMap: {
			USD: 'simple_premium_3m_66_65_3m_25_99_usd',
		},
	},
	trial: {
		days: 90,
		priceMap: {
			USD: 25.99,
		},
		originalPriceMap: {
			USD: 66.65,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 66.65,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.90_days.into_period_text'), // 'first quarter'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.90_days.sub_period_text'), // 'quarterly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.90_days.into_period_text'), // 'three months'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '3-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.90_days.sub_period_text'), // '3-month'
	}),
}
