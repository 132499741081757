import { legalLinks } from '@/helpers/userData.js'

export default {
	namespaced: true,

	state: {
		language: null,
	},

	mutations: {
		setLanguage(state, language) {
			state.language = language
		},
	},

	getters: {
		getLanguage(state) {
			return state.language
		},
		getLegalLinks(state) {
			return legalLinks(state.language)
		},
	},
}
