const { ENV, ENV_HACKERONE } = require('./helpers/environment')

const config = {
	development: {
		SupportedLanguages: ['en', 'es', 'pt', 'de', 'it', 'fr', 'sv', 'es-419', 'pl', 'tr', 'nl', 'da', 'nb'],
		Auth0Domain: 'simple-dev.us.auth0.com',
		Auth0ClientId: 'GiDHEn35LI0JH4aZAL4iBRNPMlIu9dnR',
		Auth0AudienceDomain: 'https://rest.dev.fstr.app',
		AbSmartlyApiHost: 'https://simple.absmartly.io',
		AbSmartlyApiKey: '2HkWTYAWpZGDabCrX_Dx4gAJpw-CF5j4QcZXLSSKxCpclQmido5k7qKMuZuu70zd',
		Amplitude: '8f6140c4e71822f942f0b4ce6f5f4a59',
		AmplitudePaltaWeb: '2f3a8a3d04de1a14bb085afc5d21258d',
		GTMMainContainer: 'GTM-MZ8NBTM',
		GTMActivityContainer: 'GTM-MZ8NBTM',
		OnetrustApiKey: 'f408777c-6bdf-4ff7-bfc6-6bdea2869b6e-test',
		OnetrustNoFastingApiKey: 'f4b4caa1-288c-44b8-a77f-16a29773e147-test',
		PaltaApiUrl: 'https://api.payments.simple.life',
		PaltaApiKey: 'feefe9c57a314b0bbc7baeab1eb79c41',
		HotjarSiteId: 3859227,
		HotjarVersion: 6,
		HotjarStartSessionEvent: 'hotjar_session_start',
		StrapiCMS: 'https://strapi.fstr.app',
		StrapiQA: 'https://strapi.dev.fstr.app/',
		StrapiS3: 'https://strapi.cdn-simple-life.com',
		OnboardingBuilderQA: 'https://strapi-dev.cdn-simple-life.com/onboarding-builder',
		OnboardingBuilderS3: 'https://strapi.cdn-simple-life.com/onboarding-builder',
		BrazeApiKey: 'f164faaf-179f-4102-83d8-8638a0d78f0a',
		BrazeCanvasApiKey: '09f74117-f3b2-492f-a0a8-1c35c6ecc4a0',
		BrazeCampaignApiKey: 'ddbfa2c2-1912-403b-97b1-5db8a6d29f7b',
		BrazeSdkEndpoint: 'sdk.iad-05.braze.com',
		BrazeWorkbooksBundleCanvasId: '62064bd6-caad-4f15-accb-4478e10ec0b9',
		BrazeWorkbooksEssentialCampaignId: 'f0a2bc5f-70ff-4abb-8c84-dd30fb3d0c65',
		BrazeWorkbooksBundlePlusOfferCanvasId: '2fcfb4c4-4383-4082-80b8-e370f029da61',
		BrazeWeightLossBoostCampaignId: 'f62c222b-f871-fad7-f2b3-3bf9bc7dc36d',
		BrazeSignInCodeCampaignId: '2d127673-0995-cb27-928a-a76c31233d54',
		BrazeDownloadScreenCampaignId: '350da713-032b-0932-e781-05a3c424a441',
		BrazeActivityFemaleCampaignId: 'ea2dae5a-3370-4052-b885-8336f6fe57ba',
		BrazeNewFlowWorkoutBookCampaignId: '5992ec06-efd2-9da3-ee4e-c4b01b6b2a35',
		BrazeMealPlansMenCampaignId: '66ad69f4-8441-4f8d-be5f-982c58aa49ea',
		BrazeMealPlansWomenCampaignId: '35c9818e-8110-4d8a-b4d6-cebbb6fdac0d',
		BrazeEmoEatingGuideCampaignId: '80988d12-3afc-48ed-b81b-c79d1fe3334d',
		Braze40HealthyRecipesCampaignId: 'a167f105-cb75-4ac9-8a81-94f48503276f',
		BrazeSexualHealthCampaignId: '9a21267f-4e81-4ce5-9136-1de5033a2ebb',
		BrazeAssessmentCampaignId: '9ac2304e-c49a-478d-a881-8aa3ee886bc4',
		BrazeBodyReportCampaignId: 'd03e6f35-739f-4164-92df-731b491ee601',
		BrazeRenewalReminderCampaignId: '7383750f-d18f-45e4-8b7c-3f65443d2b54',
		FstrAPIUrl: 'https://rest.dev.fstr.app/v1',
		FstrUsersAPIUrl: 'https://rest.dev.fstr.app/users-api/v1',
		SimpleAPIUrl: 'https://payment-api-dev.simple.life/',
		MarketingAPIUrl: 'https://capi.dev.fstr.app/v1',
		GeoAPIUrl: 'https://assets.simple.life/geo.json',
		FeatureFlagsUrl: 'https://configs.cdn-simple-life.com/content-api/dev/feature_flags.json',
		ApiLocalization: 'https://i18n.cdn-simple-life.com/dev/i18n/',
		AssetsPublicUrl: 'https://assets.simple.life/',
		AssessmentAPIUrl: 'https://growth-rnd-dev.it1040.workers.dev',
		stripe_product_id: 'prod_GW5JdBN2r8HlOJ',
		stripe_introductory: 'prod_MYH1pNY399u6n7',
		stripe_cheap_and_short: 'prod_NuEdBJJQBl9WMO',
		stripe_cheap_and_short_1_month: 'prod_OlhTy9tPOtVIcQ',
		stripe_cheap_and_short_3_month: 'prod_OlhUuJspakGMsr',
		stripe_cheap_and_short_1_year: 'prod_OlhVOShdsHkhIL',
		stripe_cheap_and_short_upgrade_1_year: 'prod_OHOkEYGJklKFSE',
		stripe_cheap_and_short_upgrade_3_month: 'prod_ORsb2kz7jxwfqd',
		stripe_cheap_and_short_upgrade_6_month: 'prod_ORsagwcYh7PCmB',
		stripe_cheap_and_short_upgrade_9_month: 'prod_ORsYD4Y4VMNo4U',
		stripe_meal_plan_subscription: 'prod_NwPzO4k2Oydp1U',
		stripe_upgrade_on_year: 'prod_OHNV3cpO8RWy20',
		stripe_cancel_offer_1_month: 'prod_PTz28NUVgjkNra',
		stripe_cancel_offer_3_month: 'prod_PTz3fDCPlq1Rzk',
		stripe_cancel_offer_9_month: 'prod_PTz52UdXlgpKmO',
		stripe_commitment_1_month: 'prod_PdMCBS2KG5xz6i',
		stripe_commitment_3_month: 'prod_PdMDPZYHqS41Tc',
		stripe_commitment_6_month: 'prod_PtOwy2IfpQWoSJ',
		stripe_commitment_10_month: 'prod_PdME8bH3oyxFGS',
		// If you're adding a new commitment plan, don't forget to process this case in PlanManagementCommitment.vue
		// and ask @payments-eng-team to add this plan to the commitment cancellation scenario
	},

	production: {
		SupportedLanguages: ['en', 'es', 'pt', 'de', 'it', 'fr', 'sv', 'es-419', 'pl', 'tr', 'nl', 'da', 'nb'],
		Auth0Domain: 'simple-prod.us.auth0.com',
		Auth0ClientId: 'GiDHEn35LI0JH4aZAL4iBRNPMlIu9dnR',
		Auth0AudienceDomain: 'https://rest.fstr.app',
		AbSmartlyApiHost: 'https://simple.absmartly.io',
		AbSmartlyApiKey: '2HkWTYAWpZGDabCrX_Dx4gAJpw-CF5j4QcZXLSSKxCpclQmido5k7qKMuZuu70zd',
		Amplitude: '62d757a5d66b608a2149ecda61c4fa9a',
		AmplitudePaltaWeb: '02714fe0df63e6f2d797930ed05998d2',
		GTMMainContainer: 'GTM-MNHMHJD',
		GTMActivityContainer: 'GTM-MSF5SXMW',
		OnetrustApiKey: 'f408777c-6bdf-4ff7-bfc6-6bdea2869b6e',
		OnetrustNoFastingApiKey: 'f4b4caa1-288c-44b8-a77f-16a29773e147',
		PaltaApiUrl: 'https://api.payments.simple.life',
		PaltaApiKey: 'd98b7063d72c475dbfeb03b21146be0d',
		HotjarSiteId: 3859227,
		HotjarVersion: 6,
		HotjarStartSessionEvent: 'hotjar_session_start',
		StrapiCMS: 'https://strapi.fstr.app',
		StrapiQA: 'https://strapi.dev.fstr.app/',
		StrapiS3: 'https://strapi.cdn-simple-life.com',
		OnboardingBuilderQA: 'https://strapi-dev.cdn-simple-life.com/onboarding-builder',
		OnboardingBuilderS3: 'https://strapi.cdn-simple-life.com/onboarding-builder',
		FB: '871387013207297',
		AppleClientId: 'com.amapps.simple.web.signin',
		GoogleClientId: '500728569656-of3b8ost6fh8mghk0olr61kam77pjtpv.apps.googleusercontent.com',
		BrazeApiKey: '020304c8-bbbf-4043-8322-b454553bba4d',
		BrazeCanvasApiKey: 'cbe5e595-ce0f-4e22-ba6f-5ccdc0567951',
		BrazeCampaignApiKey: '3ea60a92-6ad9-4588-afe7-5ac2837e1a3f',
		BrazeSdkEndpoint: 'sdk.iad-05.braze.com',
		BrazeWorkbooksBundleCanvasId: '7bf68f70-39a8-4bd8-9ce2-8210e67700d1',
		BrazeWorkbooksEssentialCampaignId: '8ec52a16-161d-48ad-950a-92b2d55ba703',
		BrazeWorkbooksBundlePlusOfferCanvasId: '8da9878e-2d64-dad2-edc6-ea9a8eddca9c',
		BrazeWeightLossBoostCampaignId: 'c18c7174-d2c1-e071-c593-adadc61cd9a2',
		BrazeDownloadScreenCampaignId: '82401ae1-7a49-4fc5-8672-fa3b491b9b01',
		BrazeNewFlowWorkoutBookCampaignId: '2d43fe71-b9ee-3a04-b56f-0a437dc088e2',
		BrazeMealPlansMenCampaignId: '07dbace0-d4c9-4980-9508-ab64b8e7c46a',
		BrazeMealPlansWomenCampaignId: 'e8f2cc16-3d50-4745-ba21-109fe19635ad',
		BrazeEmoEatingGuideCampaignId: '93f2fcaf-00bb-4a56-8517-1e40bd447910',
		Braze40HealthyRecipesCampaignId: '35f6fec2-3c96-49bd-859b-f7723f774c8a',
		BrazeSignInCodeCampaignId: 'fa3db25a-dc2f-48eb-b453-e7e1023f330d',
		BrazeActivityFemaleCampaignId: 'b7ea885e-9319-4582-bb4e-4e5c69176058',
		BrazeSexualHealthCampaignId: '1cb47451-1102-4431-a0b5-92a1be019056',
		BrazeAssessmentCampaignId: '19798a8d-617f-496d-b7dc-bb4df43764b0',
		BrazeBodyReportCampaignId: 'd369d956-701d-4771-8bf9-f3bf5043cba4',
		BrazeRenewalReminderCampaignId: 'd68477ac-012a-4346-8673-567465896bb3',
		FstrAPIUrl: 'https://rest.fstr.app/v1',
		FstrUsersAPIUrl: 'https://rest.fstr.app/users-api/v1',
		SimpleAPIUrl: 'https://api.simple.life/',
		MarketingAPIUrl: 'https://capi.fstr.app/v1',
		GeoAPIUrl: 'https://assets.simple.life/geo.json',
		FeatureFlagsUrl: 'https://configs.cdn-simple-life.com/content-api/feature_flags.json',
		ApiLocalization: 'https://i18n.cdn-simple-life.com/i18n/',
		AssetsPublicUrl: 'https://assets.simple.life/',
		AssessmentAPIUrl: 'https://cf-api.simple.life',
		stripe_product_id: 'prod_GgA3GIwzX7x2Wx',
		stripe_introductory: 'prod_MYGmHi1IUfZbZT',
		stripe_cheap_and_short: 'prod_NuuPPCWqACFvaX',
		stripe_cheap_and_short_1_month: 'prod_OlhZHM6P3PcfQj',
		stripe_cheap_and_short_3_month: 'prod_OlhaZcr2PRJmIV',
		stripe_cheap_and_short_1_year: 'prod_Olhb5ZuzjBQXb2',
		stripe_cheap_and_short_upgrade_1_year: 'prod_OHOaIf5cgshVkT',
		stripe_cheap_and_short_upgrade_3_month: 'prod_ORsxDWFSvGgYRX',
		stripe_cheap_and_short_upgrade_6_month: 'prod_ORswLUtnySYgTl',
		stripe_cheap_and_short_upgrade_9_month: 'prod_ORsvyosF7jYq6x',
		stripe_meal_plan_subscription: 'prod_OAiQ8j7txPNmwG',
		stripe_upgrade_on_year: 'prod_OHhd5BgG2bvOBS',
		stripe_cancel_offer_1_month: 'prod_PTz8XqWqX9Hk0C',
		stripe_cancel_offer_3_month: 'prod_PTz907OEBcLmXo',
		stripe_cancel_offer_9_month: 'prod_PTz9IfvZONMDLY',
		stripe_commitment_1_month: 'prod_PdMFm8biWA9eOw',
		stripe_commitment_3_month: 'prod_PdMF4wumoS26Y0',
		stripe_commitment_6_month: 'prod_PtOyV2Lp0etT2k',
		stripe_commitment_10_month: 'prod_PdMF9E37l3wQ6A',
	},
}

module.exports = (option) => {
	if (ENV_HACKERONE) {
		if (option === 'StrapiCMS') {
			return config.production.StrapiCMS
		}

		return config.development[option]
	}

	if (Object.prototype.hasOwnProperty.call(config[ENV], option)) {
		return config[ENV][option]
	}

	return config.production[option]
}
