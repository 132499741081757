<template>
	<transition name="slide-bottom">
		<div v-click-outside="handleClickOutside" class="hotjar-consent-popup">
			<p>
				We use session replay tool provided by
				<a class="text-primary" href="https://www.hotjar.com/" target="_blank">HotJar</a>
				to improve our services. We will see your mouse moves and clicks, but nothing you may type. Will you be OK with
				it?
			</p>
			<div class="actions">
				<button class="btn btn-link btn-block" @click.capture="$emit('close')">Not now</button>
				<button class="btn btn-primary btn-block" @click.capture="$emit('submit')">Yes</button>
			</div>
		</div>
	</transition>
</template>

<style lang="scss" scoped>
.hotjar-consent-popup {
	position: fixed;
	background-color: var(--background-primary-color);
	border-radius: 18px;
	bottom: 0;
	margin: 16px;
	padding: 16px;
	font-size: 12px;
	z-index: 10;

	p {
		margin-bottom: 12px;
	}

	.actions {
		display: flex;
		justify-content: space-between;
		gap: 16px;

		button {
			font-size: 12px;
			font-weight: 700;
			width: 100%;
			border-radius: 7px;
			padding: 8px;
			margin: 0;
		}
	}

	@media screen and (min-width: 768px) {
		width: 860px;
		left: 50%;
		transform: translateX(-50%);
		margin: 16px 0;
	}
}
</style>

<script>
export default {
	name: 'HotjarConsentPopup',
	directives: {
		clickOutside: {
			bind: function (el, binding, vnode) {
				el.clickOutsideEvent = function (event) {
					if (!(el === event.target || el.contains(event.target))) {
						vnode.context[binding.expression](event)
					}
				}
				document.body.addEventListener('click', el.clickOutsideEvent)
			},
			unbind: function (el) {
				document.body.removeEventListener('click', el.clickOutsideEvent)
			},
		},
	},
	methods: {
		handleClickOutside() {
			this.$emit('close')
		},
	},
}
</script>
