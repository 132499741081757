import config from '@/config'
import axios from 'axios'

export default {
	namespaced: true,

	state: {
		purchasedProduct: null,
		upgrades: {
			original: null,
			discounted: null,
		},
		promocode: null,
		removeDiscountAfterCountdown: false,
	},

	mutations: {
		setPurchasedProduct(state, product) {
			state.purchasedProduct = product
		},

		setUpgrades(state, upgrades) {
			state.upgrades.original = upgrades.original
			state.upgrades.discounted = upgrades.discounted
		},

		setPromocode(state, promocode) {
			state.promocode = promocode
		},

		setRemoveDiscountAfterCountdown(state, status) {
			state.removeDiscountAfterCountdown = status
		},
	},

	getters: {
		getPromocode(state) {
			return state.promocode
		},
	},

	actions: {
		async setPromocodeByName({ commit }, promocode) {
			try {
				const { data } = await axios.get(`${config('SimpleAPIUrl')}v1/api/marketing/promocode`, {
					params: {
						promocode,
					},
				})

				commit('setPromocode', data)
			} catch (e) {
				commit('setPromocode', null)
				throw e
			}
		},
	},
}
