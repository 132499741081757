import request from '@/helpers/request'
import config from '@/config'
import Sentry from '@/modules/Sentry'

/**
 * https://simple-admin.fstr.app/config-builder/config/15
 */

export default {
	namespaced: true,
	state: {
		stateHydrated: false,
		// {boolean} Enable credit card on paywall (via stripe)
		creditCard: true,
		// {boolean} Enable the whole paywall, after email redirected straight to Download page
		paywall: true,
		// {boolean} Enable paypal on paywall (via braintree)
		paypal: true,
		// {boolean} Enable Apple Pay via stripe on paywall
		applePayStripe: true,
		// {boolean} Enable Google Pay via stripe on paywall
		googlePay: true,
		// {{ [stripe|braintree|palta]: number }} Delay for /survey/account page (key - payment provider, value - delay in ms)
		accountPageDelay: {},
		// {('none'|'console'|'emit'|'both')} Log level for billing
		billingLogLevel: 'none',
		amplitude: {
			disabled: false,
			blackList: [],
			whiteList: [],
		},
		palta: {
			blackList: [],
			whiteList: [],
		},
		organicUtmValues: {
			sources: [],
			campaigns: [],
		},
		variables: {
			paywallStoresRatingScore: 4.8,
			paywallStoresRatingsNumber: 300000,
			paywallTrustPilotReviews: 21482,
		},
		paymentGateway: 'palta',
		funnelsPreload: {},
		ob: true,
		links: {
			support: 'https://help.simple.life/',
			onelink: {
				noAutoLoginFemale: 'https://fasting.onelink.me/exOi/3e8cz11t',
				noAutoLoginMale: 'https://fasting.onelink.me/exOi/6zh7ofk4',
			},
		},
	},
	getters: {
		FEAT_CREDIT_CARD_ENABLED(state) {
			return state.creditCard
		},
		FEAT_PAYWALL_ENABLED(state) {
			return state.paywall
		},
		FEAT_PAYPAL_ENABLED(state) {
			return state.paypal
		},
		FEAT_APPLEPAY_STRIPE_ENABLED(state) {
			return state.applePayStripe
		},
		FEAT_GOOGLEPAY_ENABLED(state) {
			return state.googlePay
		},
		FEAT_ACCOUNT_PAGE_DELAY(state) {
			return state.accountPageDelay
		},
		FEAT_BILLING_LOG_LEVEL(state) {
			return state.billingLogLevel
		},
		FEAT_AMPLITUDE_WHITE_LIST(state) {
			return state.amplitude?.whiteList ?? []
		},
		FEAT_AMPLITUDE_DISABLED(state) {
			return state.amplitude?.disabled
		},
		FEAT_AMPLITUDE_BLACK_LIST(state) {
			return state.amplitude?.blackList ?? []
		},
		FEAT_PALTA_WHITE_LIST(state) {
			return state.palta?.whiteList ?? []
		},
		FEAT_PALTA_BLACK_LIST(state) {
			return state.palta?.blackList ?? []
		},
		FEAT_ORGANIC_UTM_VALUES(state) {
			return state.organicUtmValues
		},
		FEAT_PAYMENT_GATEWAY(state) {
			return state.paymentGateway
		},
		FEAT_FUNNELS_PRELOAD(state) {
			return state.funnelsPreload ?? {}
		},
		FEAT_FUNNELS_OB_ENABLED(state) {
			return state.ob
		},
		FEAT_LINK_SUPPORT(state) {
			return state.links.support
		},
		FEAT_LINK_ONELINK(state) {
			return state.links.onelink
		},
	},
	mutations: {
		setFeatureValue(state, payload) {
			state[payload.feature] = payload.value
		},
		setStateHydrated(state, payload) {
			state.stateHydrated = payload
		},
	},
	actions: {
		async hydrateCoreFeatures({ state, commit }) {
			if (state.stateHydrated) {
				return
			}
			try {
				const response = await request(config('FeatureFlagsUrl'))

				Object.entries(response).forEach(([feature, value]) => commit('setFeatureValue', { feature, value }))
			} catch (error) {
				Sentry.withScope((scope) => {
					scope.setTags({
						coreFeatures: true,
					})
					scope.captureException(error)
				})
			} finally {
				commit('setStateHydrated', true)
				commit('status/setResourceReadiness', 'coreFeatures', { root: true })
			}
		},
	},
}
