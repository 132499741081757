<template>
	<div class="braintree-card">
		<div id="card-container">
			<div class="braintree-card__field">
				<div class="braintree-card__label">{{ $t('pages.program.form.card_number') }}</div>
				<div id="card-number" class="braintree-card__input"></div>
			</div>
			<div class="braintree-card__row">
				<div class="braintree-card__field">
					<div class="braintree-card__label">{{ $t('pages.program.form.expires_on') }}</div>
					<div id="expiration-date" class="braintree-card__input"></div>
				</div>
				<div class="braintree-card__field">
					<div class="braintree-card__label">{{ $t('pages.program.form.security_code') }}</div>
					<div id="cvv" class="braintree-card__input"></div>
				</div>
			</div>
			<button
				@click="$emit('submit')"
				id="card-button"
				class="btn btn-success mt-4 btn-submit text-bold"
				:class="buttonClassList"
			>
				<LockIcon class="btn__icon" />
				<div class="btn__cta">{{ cta }}</div>
				<div class="spinner-border spinner-border-sm"></div>
			</button>
		</div>
	</div>
</template>

<script>
import LockIcon from '@/components/icons/LockIcon.vue'

export default {
	name: 'BraintreeCard',

	components: {
		LockIcon,
	},

	props: {
		buttonClassList: {
			type: Object,
			default: () => ({}),
		},
		cta: {
			type: String,
			required: true,
		},
	},
}
</script>

<style lang="scss" scoped>
.braintree-card {
	&__label {
		margin-bottom: 9px;
		font-size: 12.6px;
		color: var(--input-text-color);
	}

	&__input {
		height: 48px;
		border: 2px solid var(--input-border-color);
		padding: 0 12px;
		font-size: 16px;
		color: var(--input-text-color);
		border-radius: 8px;
		transition: border-color 0.3s ease-in-out;

		&.braintree-hosted-fields-invalid {
			border-color: var(--error-color);
		}

		&.braintree-hosted-fields-focused {
			border-color: var(--input-border-color-focus);
		}
	}

	&__row {
		display: flex;
		gap: 16px;
		margin-top: 1rem;
	}
}
</style>
