import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_1M_14_39',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_1m_29_99_1m_14_39_usd',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 14.39,
		},
		originalPriceMap: {
			USD: 29.99,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.30_days.period_text'), // '1-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		pricePerDayWithoutDiscount: 0.59,
	}),
}
