<template>
	<div class="page instagram">
		<BaseHeader v-if="!hideHeaderComponent" class="header" />

		<div class="page__content">
			<router-view></router-view>
		</div>
		<notification v-if="notification" :message="notification"></notification>
	</div>
</template>

<script>
import Notification from '@/components/Notification.vue'
import BaseHeader from '../BaseHeader.vue'

export default {
	name: 'ScreenLayout',

	components: {
		Notification,
		BaseHeader,
	},

	props: {
		hideHeader: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		hideHeaderComponent() {
			return this.$route.meta.hideHeader || this.hideHeader
		},

		notification() {
			return this.$store.state.notification
		},
	},
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.header > .container-fluid {
	position: relative;
}
</style>
