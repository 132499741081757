export default {
	id: 'SIMPLE_UPGRADE_3M_53_97',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_3m_53_97_usd',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 53.97,
		},
	},
	options: () => ({
		// FIXME: will be refactored before rollout
		currentSubscriptionRateText: (context) => {
			return `You received an introductory rate of ${context.trialPrice} for your first week. After that, your subscription is set to renew at the standard $29.99/mo.`
		},
		upgradeOfferText: 'Upgrade to our 3-month plan now to claim a discounted rate of $17.99/mo and continue saving!',
		barChartTooltip: 'Next charge',
		renewalPeriod: 'monthly',
		upgradePlanPeriod: '3-month',
		upgradeRate: '/mo',
		currentPriceLabel: 'Current monthly price',
		offerPriceLabel: 'Monthly price with offer',
		totalLabel: 'Total for 3 months',
		pricingTableTitle: 'Upgrade to 3 months to claim offer',
	}),
}
