<template>
	<div class="theme-provider">
		<slot></slot>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
	name: 'ThemeProvider',

	watch: {
		theme: {
			handler(theme) {
				document.body.setAttribute('data-theme', theme)
				this.$store.commit('setAppTheme', theme)
			},
			immediate: true,
		},
	},

	computed: {
		...mapGetters({
			funnelType: 'location/getFunnelType',
			isObFunnelEnabled: 'coreFeatures/FEAT_FUNNELS_OB_ENABLED',
		}),

		theme() {
			if (this.IS_ADAPTED_MALE_FUNNEL_EXP || (this.IS_MALE_OB_FUNNEL && this.isObFunnelEnabled)) {
				return 'navy'
			}
			return this.funnelType.theme ?? 'light'
		},
	},
}
</script>
