import platform from 'platform'

export const os = platform.os.family
export const version = platform.os.version
export const browserEngine = platform.layout
export const browserName = platform.name
export const browserVersion = parseInt(platform.version) || 0
export const isMobile = ['Android', 'iOS'].includes(platform.os.family)

export const isIos = platform.os.family === 'iOS'
