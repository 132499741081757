<template>
	<div ref="observeDiv">
		<slot></slot>
	</div>
</template>

<script>
export default {
	name: 'PaymentMethodObserver',

	props: {
		targetSelector: {
			type: String,
			required: true,
		},
	},

	data() {
		return {
			observer: null,
		}
	},

	methods: {
		observe() {
			this.observer = new MutationObserver((mutationsList, observer) => {
				if (this.$refs.observeDiv.querySelector(this.targetSelector)) {
					this.$emit('ready')
					observer.disconnect()
				}
			})

			this.observer.observe(this.$refs.observeDiv, { childList: true, subtree: true })
		},
	},

	mounted() {
		this.observe()
	},

	beforeDestroy() {
		this.observer?.disconnect()
	},
}
</script>
