const ONETRUST_CATEGORIES_ENUM = {
	C0001: 'necessary_cookies',
	C0002: 'performance_cookies',
	C0003: 'functional_cookies',
	C0004: 'targeting_cookies',
}

export default {
	namespaced: true,
	state: {
		inited: false,
		/**
		 * OneTrust selected categories. Actual list is in the `window.OnetrustActiveGroups` variable.
		 * @type {Array<string>}
		 */
		activeGroups: [],
		/**
		 * Flag to indicate if user has closed OneTrust banner. The copy of `OneTrust.IsAlertBoxClosed()`.
		 * @type {Boolean}
		 */
		bannerClosed: false,
		/**
		 * Flag about necessity to interact with cookies popup. We show opt-in popup for EU, GB, California, Florida, Pennsylvania.
		 */
		optInModel: false,
	},
	getters: {
		getActiveGroups(state) {
			return state.activeGroups.map((group) => ONETRUST_CATEGORIES_ENUM[group] || group)
		},

		getOptInModel(state) {
			return state.optInModel
		},

		/**
		 * Check if we can hide OneTrust banner automatically.
		 * @param state
		 * @returns {boolean}
		 */
		isBannerAutoClosable(state) {
			return state.inited && !state.bannerClosed && !state.optInModel
		},

		/**
		 * Check if user has active consent for group. It's a getter to check GDPR consents.
		 * If getOptInModel !== 'opt-in' then we always return true.
		 * @param state
		 * @param getters
		 * @returns {function('necessary_cookies'|'performance_cookies'|'functional_cookies'|'targeting_cookies'): boolean}
		 */
		isActiveConsentByName: (state, getters) => (consent) => {
			if (!getters.getOptInModel) {
				return true
			}

			return getters.getActiveGroups.includes(consent)
		},
	},
	mutations: {
		setInited(state, inited) {
			state.inited = inited
		},
		setActiveGroups(state, activeGroups) {
			state.activeGroups = activeGroups
		},
		setBannerClosed(state, bannerClosed) {
			state.bannerClosed = bannerClosed
		},
		setOptInModel(state, optInModel) {
			state.optInModel = optInModel
		},
	},
}
