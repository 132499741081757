export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_1M_44_00',
	providers: {
		paltaMap: {
			USD: 'simple_premium_1m_30_1m_44_usd',
			GBP: 'simple_premium_1m_29_99_1m_44_gbp',
			CAD: 'simple_premium_1m_49_99_1m_69_cad',
			AUD: 'simple_premium_1m_49_99_1m_69_aud',
		},
	},
	trial: {
		days: 30,
		priceMap: {
			USD: 44,
			GBP: 44,
			CAD: 69,
			AUD: 69,
		},
		originalPriceMap: {},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 30,
			GBP: 30,
			CAD: 50,
			AUD: 50,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.30_days.into_period_text'), // 'first month'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.30_days.period_text'), // '1-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.30_days.sub_period_text'), // 'monthly'
	}),
}
