<template>
	<div class="checkout-form">
		<div id="primer-checkout-container"></div>
		<button @click="$emit('submit')" class="btn btn-success mt-4 btn-submit text-bold" :class="buttonClassList">
			<LockIcon class="btn__icon" />
			<div class="btn__cta">{{ cta }}</div>
			<div class="spinner-border spinner-border-sm"></div>
		</button>
	</div>
</template>

<script>
import LockIcon from '@/components/icons/LockIcon.vue'

export default {
	name: 'PrimerCard',

	components: {
		LockIcon,
	},

	props: {
		buttonClassList: {
			type: Object,
			default: () => ({}),
		},
		cta: {
			type: String,
			required: true,
		},
	},
}
</script>
