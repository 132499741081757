export default {
	namespaced: true,

	state: {
		pending: {
			user: false,
			redirect: false,
		},
		ready: {
			network: false,
			i18n: false,
			absmartly: false,
			coreFeatures: false,
		},
	},

	mutations: {
		setResourcePending(state, name) {
			state.pending[name] = true
		},

		unsetResourcePending(state, name) {
			state.pending[name] = false
		},

		setResourceReadiness(state, name) {
			state.ready[name] = true
		},
	},

	getters: {
		resourceIsPending: (state) => (name) => {
			return state.pending[name]
		},

		networkResourceIsReady(state) {
			return state.ready.network
		},

		coreFeaturesResourceIsReady(state) {
			return state.ready.coreFeatures
		},

		requiredResourcesAreReady(state) {
			return ['i18n', 'network', 'absmartly', 'coreFeatures'].every((name) => state.ready[name])
		},
	},
}
