export default {
	namespaced: true,

	state: {
		premiumAccess: {
			cost: 20,
			completed: true,
		},
		nutritionWorkbook: {
			cost: 10,
			completed: false,
		},
		workoutWorkbook: {
			cost: 10,
			completed: false,
		},
		installApp: {
			cost: 10,
			completed: false,
		},
		signIn: {
			cost: 50,
			completed: false,
		},
	},

	getters: {},

	mutations: {
		markCompleted(state, property) {
			state[property].completed = true
		},
	},

	actions: {},
}
