import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_1M_29_99_1W_3_99',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_1m_29_99_1w_3_99_usd',
		},
	},
	trial: {
		days: 7,
		priceMap: {
			USD: 3.99,
		},
		originalPriceMap: {
			USD: 9.99,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 29.99,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.7_days.into_period_text'), // 'first week'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.7_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.7_days.into_period_text'), // 'first week'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.7_days.period_text'), // '1-week'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.7_days.sub_period_text'), // 'monthly',
		pricePerDayWithoutDiscount: 0.71,
	}),
}
