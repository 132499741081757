import config from '@/config'

export default {
	id: 'SIMPLE_UPGRADE_6M_79',
	providers: {
		stripe: config('stripe_cheap_and_short_upgrade_6_month'),
		braintree: 'pro_short_cheap_upgrade',
		paltaMap: {
			USD: 'simple_premium_6m_79_usd',
			EUR: 'simple_premium_6m_79_eur',
			GBP: 'simple_premium_6m_79_gbp',
			AUD: 'simple_premium_6m_119_aud',
			CAD: 'simple_premium_6m_119_cad',
			DKK: 'simple_premium_6m_589_dkk',
			SEK: 'simple_premium_6m_939_sek',
			NOK: 'simple_premium_6m_939_nok',
			BRL: 'simple_premium_6m_389_brl',
			MXN: 'simple_premium_6m_1319_mxn',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 6,
		priceMap: {
			USD: 79,
			EUR: 79,
			GBP: 79,
			AUD: 119,
			CAD: 119,
			DKK: 589,
			SEK: 939,
			NOK: 939,
			BRL: 389,
			MXN: 1319,
		},
	},
	options: () => ({}),
}
