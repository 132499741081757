<template>
	<Portal :to="teleportTo" :disabled="!teleportTo">
		<div class="palta-card">
			<div class="card-form">
				<div v-show="rendered">
					<PrimerCard
						v-show="integration === 'primer'"
						:button-class-list="buttonClassList"
						:cta="cta"
						@submit="submit"
					/>
					<BraintreeCard
						v-show="integration === 'braintree'"
						:button-class-list="buttonClassList"
						:cta="cta"
						@submit="submit"
					/>
				</div>
				<CardSkeleton v-show="!rendered" />

				<slot name="beforeButton"></slot>
			</div>
		</div>
	</Portal>
</template>

<script>
import CardSkeleton from './CardSkeleton'
import { Portal } from 'portal-vue'
import PrimerCard from './primer/PrimerCard.vue'
import BraintreeCard from './braintree/BraintreeCard.vue'

export default {
	name: 'CardForm',

	components: {
		CardSkeleton,
		Portal,
		PrimerCard,
		BraintreeCard,
	},

	props: {
		cta: {
			type: String,
			required: true,
		},
		buttonClassList: {
			type: Object,
			default: () => ({}),
		},
		rendered: {
			type: Boolean,
			default: false,
		},
		teleportTo: {
			type: String,
			default: null,
		},
		integration: {
			type: String,
			default: 'primer',
			validator: (value) => ['primer', 'braintree'].includes(value),
		},
	},

	methods: {
		submit() {
			this.$emit('submit')
		},
	},

	mounted() {
		this.$emit('ready')
	},
}
</script>

<!-- eslint-disable-next-line vue-scoped-css/enforce-style-type -->
<style lang="scss">
.palta-card {
	svg {
		display: inline;
		width: auto;
	}

	.btn-submit {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		width: 100%;
		font-weight: 700;

		&:focus,
		&:active {
			box-shadow: none;
		}

		svg {
			margin-right: 0.6rem;
		}

		.spinner-border {
			position: absolute;
			left: 50%;
			top: 50%;
			margin-top: -0.68rem;
			margin-left: -0.68rem;
			opacity: 0;
		}

		&.loading {
			.spinner-border {
				opacity: 1;
			}
			.btn__icon,
			.btn__cta {
				opacity: 0;
			}
		}
	}
}

.checkout-form {
	div:empty:not(.spinner-border) {
		display: none;
	}

	[data-testid='FormInputGroups'] {
		div:only-child {
			max-width: 100%;
		}
	}
}

.PrimerCheckout {
	&__apmButton {
		height: auto !important;
	}

	&__apmButtonContainer {
		&:not(:last-child) {
			margin-bottom: 0 !important;
		}
	}

	&__formInput {
		display: flex;
		align-items: center;
	}

	@media (max-width: 600px) {
		&__formField {
			&.PrimerCheckout__formField--dual {
				flex-direction: row !important;
				gap: 16px;

				& > {
					.PrimerCheckout__formField + .PrimerCheckout__formField {
						margin-top: 0 !important;
					}
				}
			}
		}
	}
}
</style>
