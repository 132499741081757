<template>
	<Portal :to="teleportTo" :disabled="!teleportTo">
		<div id="applepay-button" class="applepay-button" :class="{ disabled: classList.disabled }" ref="observeDiv"></div>
	</Portal>
</template>

<script>
import { Portal } from 'portal-vue'

export default {
	name: 'ApplePayButton',

	components: { Portal },

	props: {
		teleportTo: {
			type: String,
			default: null,
		},
		classList: {
			type: Object,
			default: () => ({}),
		},
	},

	data() {
		return {
			observer: null,
		}
	},

	methods: {
		observe() {
			this.observer = new MutationObserver((mutationsList, observer) => {
				if (this.$refs.observeDiv.querySelector('.primer-apple-pay-button')) {
					this.$emit('ready')
					observer.disconnect()
				}
			})

			this.observer.observe(this.$refs.observeDiv, { childList: true })
		},

		disconnect() {
			if (this.observer) {
				this.observer.disconnect()
			}
		},
	},

	mounted() {
		this.$nextTick(() => {
			this.observe()
		})
	},
	beforeDestroy() {
		this.disconnect()
	},
}
</script>

<style lang="scss" scoped>
.applepay-button {
	margin-top: 2rem;

	&.disabled {
		opacity: 0.5;
		pointer-events: none;

		> :deep(button) {
			pointer-events: none !important;
		}
	}
}
:deep(.primer-apple-pay-button) {
	height: 54px !important;
	border-radius: 0.45rem;
}
</style>
