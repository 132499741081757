import config from '@/config'

export default {
	id: 'SIMPLE_PAYWALL_3M_59_99_3M_59_99',
	providers: {
		stripe: config('stripe_cheap_and_short'),
		braintree: 'pro_short_cheap',
		paltaMap: {
			USD: 'simple_premium_3m_59_99_3m_59_99_usd',
			EUR: 'simple_premium_3m_59_99_3m_59_99_eur',
			GBP: 'simple_premium_3m_59_99_3m_59_99_gbp',
			AUD: 'simple_premium_3m_99_99_3m_99_99_aud',
			CAD: 'simple_premium_3m_99_99_3m_99_99_cad',
			DKK: 'simple_premium_3m_459_3m_459_mxn',
			SEK: 'simple_premium_3m_699_3m_699_sek',
			NOK: 'simple_premium_3m_699_3m_699_nok',
			BRL: 'simple_premium_3m_299_99_3m_299_99_brl',
			MXN: 'simple_premium_3m_999_3m_999_mxn',
		},
	},
	trial: {
		days: 90,
		priceMap: {
			USD: 59.99,
			EUR: 59.99,
			GBP: 59.99,
			AUD: 99.99,
			CAD: 99.99,
			DKK: 459,
			SEK: 699,
			NOK: 699,
			BRL: 299.99,
			MXN: 999,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 59.99,
			EUR: 59.99,
			GBP: 59.99,
			AUD: 99.99,
			CAD: 99.99,
			DKK: 459,
			SEK: 699,
			NOK: 699,
			BRL: 299.99,
			MXN: 999,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.90_days.into_period_text'), // 'first quarter'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.90_days.sub_period_text'), // 'quarterly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.90_days.into_period_text'), // 'three months'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '3-month'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.90_days.sub_period_text'), // '3-month'
	}),
}
