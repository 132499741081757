import Hotjar from '@hotjar/browser'

export default {
	/**
	 * @param Vue The Vue instance.
	 * @param {object} options Configuration options.
	 * @param {number} options.siteId
	 * @param {number} options.hotjarVersion
	 * @param {object} options.sessionStartEvent
	 * @param {boolean} options.debug Enable debug mode.
	 */
	install(Vue, options = {}) {
		const { siteId, hotjarVersion, debug } = options
		if (!siteId || !hotjarVersion) {
			throw new Error('Hotjar siteId and hotjarVersion are required')
		}

		let isInitialized = false

		function init() {
			if (!isInitialized) {
				Hotjar.init(siteId, hotjarVersion, {
					debug,
				})
				isInitialized = true
			}
		}

		function startSession(userId, userAttributes) {
			if (!isInitialized) {
				console.warn('Hotjar is not initialized. Call $hj.init() before starting a session.')
			}
			Hotjar.identify(userId, userAttributes)
			Hotjar.event(options.sessionStartEvent)
		}

		Vue.prototype.$hj = {
			init,
			startSession,
		}
	},
}
