import { mapGetters, mapState } from 'vuex'

export default {
	computed: {
		...mapState({
			subscription: 'subscription',
		}),
		...mapGetters({
			language: 'i18n/getLanguage',
			domain: 'location/domain',
			accountPlatform: 'location/accountPlatform',
			getQueryParam: 'location/getQueryParam',
			userBmi: 'getBmi',
			countryCode: 'getCountryCode',
			getPropertyValue: 'survey/getPropertyValue',
			commitmentPlanPeriod: 'getCommitmentPlanPeriod',
			appTheme: 'getAppTheme',
			appArea: 'getAppArea',
			isOrganicTrafficOnboarding: 'userFlags/getIsOrganicTrafficOnboarding',
		}),
		IS_APPLY_TAX_TO_PRODUCTS_ENABLED() {
			return false
		},
		IS_ACCOUNT_DELETION_DEEPLINK_EXP() {
			return this.getQueryParam('exp') === 'ios_improve_account_deletion'
		},
		IS_ACCOUNT_DELETION_DEEPLINK_V2_EXP() {
			return this.getQueryParam('exp') === 'ios_improve_account_deletion_v2'
		},
		IS_CHANGE_SUBSCRIPTION_ON_CANCEL_EXP() {
			return this.$absmartly.treatment('web_change_subscription_on_cancel')
		},
		IS_CONFIRM_EMAIL_BY_CODE_EXP() {
			return (
				(this.accountPlatform.ios || this.accountPlatform.android) &&
				this.$absmartly.treatment('web_confirm_email_by_code')
			)
		},
		IS_NEW_ACCOUNT_PAGE_EXP() {
			return (
				this.accountPlatform.ios &&
				Boolean(!this.commitmentPlanPeriod) &&
				this.$absmartly.treatment('web_new_account_page')
			)
		},
		IS_DISABLE_AUTOLOGIN_EXP() {
			return this.$absmartly.treatment('web_removing_auto_login')
		},
		IS_COMMITMENT_PLANS_MALE_EXP() {
			return this.$absmartly.treatment('web_male_commitment_plans')
		},
		IS_COMMITMENT_PLANS_ACTIVITY_EXP() {
			return [
				this.$absmartly.treatment('web_activity_commitment_plans'),
				this.$absmartly.treatment('web_pilates_commitment_plans'),
				this.$absmartly.treatment('web_yoga_commitment_plans'),
			].some(Boolean)
		},
		IS_CRO_BULK_EXP() {
			return this.$absmartly.treatment('web_cro_bulk_v2')
		},
		IS_ADAPTED_MALE_FUNNEL_EXP() {
			return this.$absmartly.treatment('web_adapted_male_funnel')
		},
		IS_HIDE_ZIPCODE_FIELD() {
			return this.$absmartly.treatment('web_hide_zipcode_field')
		},
		IS_PRE_PAYWALL_FOR_BETTER_MONETIZATION_EXP() {
			return this.$absmartly.treatment('web_pre_paywall_for_better_monetization')
		},
		IS_SOCIAL_PROOF_EXP() {
			return this.$absmartly.treatment('web_social_proof')
		},
		IS_WELCOME_AGE_SCREEN_EXP() {
			return this.$absmartly.treatment('web_welcome_age_screen')
		},
		IS_APPLE_PAY_AS_DEFAULT_CTA_EXP() {
			return this.$absmartly.treatment('web_apple_pay_as_default_cta')
		},
		IS_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_face_massage_upsell_exp')
		},
		IS_ACTIVITY_NEW_UPGRADES_EXP() {
			return this.$absmartly.treatment('web_activity_new_upgrades')
		},
		IS_YOGA_UPSELLS_PRICES_EXP() {
			return this.$absmartly.treatment('web_yoga_upsells_prices')
		},
		IS_FEMALE_50_PAYWALL_OPTIMIZATION_EXP() {
			const age = this.getPropertyValue('age', true)
			if (age < 50) {
				return false
			}
			return this.$absmartly.treatment('web_female_50_paywall_optimization')
		},
		IS_MALE_PAYWALL_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_male_paywall_optimization')
		},
		IS_AS_FEATURED_IN_PAYWALL_EXP() {
			return this.$absmartly.treatment('web_as_featured_in_paywall')
		},
		IS_FEMALE_ACTIVITY_BEFORE_PREDICT_EXP() {
			return this.$absmartly.treatment('web_female_activity_before_predict_ob_funnel')
		},
		IS_FEMALE_ACTIVITY_NEW_PRICE_CONSENT_EXP() {
			return this.$absmartly.treatment('web_activity_new_price_consent')
		},
		IS_POST_PURCHASE_OPTIMIZATION_EXP() {
			return ['main', 'female', 'aff-main', 'aff-female', 'male', 'aff-male'].includes(this.appArea)
		},
		IS_POST_PURCHASE_OPTIMIZATION_i2_EXP() {
			return this.$absmartly.treatment('web_post_purchase_optimization') === 2
		},
		IS_LOADER_LIKE_STORIES_FEMALE_EXP() {
			return this.$absmartly.treatment('web_loader_like_stories_female')
		},
		IS_FEMALE_ACTIVITY_NEW_UI_EXP() {
			return this.$absmartly.treatment('web_activity_new_ui')
		},
		YOGA_FUNNEL_PAYWALL_OPTIMIZATION_V4() {
			return this.$absmartly.treatment('web_funnel_paywall_optimization_v4')
		},
		IS_SEXUAL_HEALTH_UPSELL_EXP() {
			return this.$absmartly.treatment('web_sexual_health_upsell')
		},
		IS_FEMALE_ACTIVITY_NEW_UPGRADES_EXP() {
			if (this.IS_COMMITMENT_PLANS_ACTIVITY_EXP) {
				return false
			}

			return [
				!this.isOrganicTrafficOnboarding && this.IS_FEMALE_ACTIVITY_OB_FUNNEL && this.countryCode === 'us',
				!this.isOrganicTrafficOnboarding && this.IS_FEMALE_ACTIVITY_CHAIR_YOGA_OB_FUNNEL && this.countryCode === 'us',
				!this.isOrganicTrafficOnboarding && this.IS_FEMALE_ACTIVITY_PILATES_OB_FUNNEL && this.countryCode === 'us',
			].some(Boolean)
		},
		IS_NEW_AGE_SCREEN_EXP() {
			return this.$absmartly.treatment('web_new_age_screen')
		},
		IS_NEW_CANCELLATION_OFFER_AND_UI_EXP() {
			return !Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_new_cancellation_offer_and_ui')
		},
		IS_MONEYBACK_UPDATE_EXP() {
			return this.$absmartly.treatment('web_moneyback_update')
		},
		IS_YOGA_FACE_MASSAGE_UPSELL_EXP() {
			return this.$absmartly.treatment('web_yoga_face_massage_upsell_exp')
		},
		IS_MAIN_LOADER_OPTIMIZATION_EXP() {
			return this.$absmartly.treatment('web_main_loader_optimization')
		},
		IS_EVENT_PERSONALIZATION_EXP() {
			return this.$absmartly.treatment('web_event_personalization')
		},
		IS_MALE_NEW_WORKBOOKS_EXP() {
			return this.$absmartly.treatment('web_male_new_workbooks')
		},
		IS_COMMITMENT_DELAY_VS_FEES_EXP() {
			return Boolean(this.commitmentPlanPeriod) && this.$absmartly.treatment('web_commitment_delay_vs_fees')
		},
		IS_FULL_BODY_REPORT_EXP() {
			return this.$absmartly.treatment('web_full_body_report')
		},
		IS_CHANGE_INTRO_TO_SUB_EXP() {
			return this.$absmartly.treatment('web_change_intro_to_sub')
		},
		IS_MALE_PAYWALL_OPTIMIZATION_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_male_paywall_optimization_hotjar_track')
		},
		IS_MALE_FUNNEL_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_male_funnel_hotjar_track')
		},
		IS_FEMALE_FUNNEL_HOTJAR_EXP() {
			return this.$absmartly.treatment('web_female_funnel_hotjar_track')
		},
		IS_ACTIVITY_WELCOME_FOR_GOOGLE_EXP() {
			return this.$absmartly.treatment('web_activity_welcome_for_google')
		},
		IS_ACTIVITY_UPDATE_MONEYBACK_GUARANTEE_EXP() {
			return [
				this.$absmartly.treatment('web_activity_update_moneyback_guarantee'),
				this.$absmartly.treatment('web_yoga_update_moneyback_guarantee'),
				this.$absmartly.treatment('web_pilates_update_moneyback_guarantee'),
			].some(Boolean)
		},
		IS_COMMITMENT_PLANS_WITH_CONSENT_EXP() {
			return this.$absmartly.treatment('web_commitment_plans_with_consent')
		},
		IS_FORBES_ON_FIRST_LOADER_EXP() {
			return this.$absmartly.treatment('web_forbes_on_first_loader')
		},
	},
}
