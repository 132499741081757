export default {
	id: 'SIMPLE_UPGRADE_9M_79_95',
	providers: {
		stripe: '',
		braintree: '',
		paltaMap: {
			USD: 'simple_premium_9m_79_95_usd',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 79.95,
		},
	},
	options: () => ({
		// FIXME: will be refactored before rollout
		currentSubscriptionRateText: (context) => {
			return `You received a discounted rate of ${context.trialPrice} for your first 3 months. After that, your subscription is set to renew at the standard $59.99 every 3 months.`
		},
		upgradeOfferText: 'Upgrade to our 9-month plan now to claim your extra-discounted $26.65 rate and continue saving!',
		barChartTooltip: 'Next charge',
		renewalPeriod: '3-month',
		upgradePlanPeriod: '9-month',
		upgradeRate: '/3mos',
		currentPriceLabel: 'Current 3-month price',
		offerPriceLabel: '3-month price with offer',
		totalLabel: 'Total for 9 months',
		pricingTableTitle: 'Upgrade to 9 months to claim offer',
	}),
}
