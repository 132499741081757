import { domainsFromConfig, FUNNELS } from '@/data/funnels'

const DEFAULT_PAYWALL_ROUTE = 'plan-main'
const DEFAULT_FUNNEL_TYPE = FUNNELS.MAIN_UNIVERSAL_FUNNEL

const findFunnelById = (funnelId) => Object.values(FUNNELS).find((funnel) => funnel.id === funnelId)

/**
 * Defines the funnel based on the provided app area and country.
 *
 * @param {string} appArea - The app area.
 * @param {string} country - The country.
 * @param {Function} errorCallback - The error callback function. (optional)
 * @returns {Object} - An object containing the funnel type and paywall route.
 */

export function defineFunnelByAppArea(appArea, country, errorCallback = null, experimentCallback = null) {
	const currentAreaConfig = domainsFromConfig
		.map((d) => Object.values(d.domainConfig))
		.flat()
		.find((v) => v.appArea === appArea)

	const funnels = currentAreaConfig?.funnels

	if (!funnels) {
		if (errorCallback) {
			errorCallback('No funnel found for this appArea', { appArea })
		}
		return { funnelType: DEFAULT_FUNNEL_TYPE, paywallRoute: DEFAULT_PAYWALL_ROUTE }
	}

	if (experimentCallback) {
		const funnelByExp = funnels.find(
			(funnel) => funnel.expId && experimentCallback(funnel.expId, Boolean(funnel.exposureImmediate)),
		)
		if (funnelByExp) {
			const tags = funnelByExp?.userTags || currentAreaConfig?.userTags
			const flags = currentAreaConfig?.userFlags
			return {
				funnelType: findFunnelById(funnelByExp.funnelId),
				paywallRoute: funnelByExp.paywallRoute,
				userTags: tags,
				userFlags: flags,
			}
		}
	}

	const funnelByCountry =
		funnels.find((funnel) => funnel.country === country) || funnels.find((funnel) => funnel.country === 'default')

	const tags = funnelByCountry?.userTags || currentAreaConfig?.userTags
	const flags = currentAreaConfig?.userFlags

	return {
		funnelType: findFunnelById(funnelByCountry.funnelId),
		paywallRoute: funnelByCountry.paywallRoute,
		userTags: tags,
		userFlags: flags,
	}
}

/**
 * Defines a funnel based on the provided parameters.
 *
 * @param {Object} params - The parameters for defining the funnel.
 * @param {string} params.country - The country for the funnel.
 * @param {string} params.domain - The domain for the funnel.
 * @param {string} params.prefix - The prefix for the funnel.
 * @param {Function} experimentCallback - The callback function for checking experiment ID.
 * @param {Function} errorCallback - The callback function for handling errors.
 * @returns {Object} - The defined funnel type and paywall route.
 */

export function defineFunnelByParams(params, experimentCallback, errorCallback) {
	const { country, domain, prefix } = params

	const domainConfig = domainsFromConfig.find((item) => item.regex.test(domain))?.domainConfig
	if (!domainConfig) {
		errorCallback('No funnel found for this domain', { domain })
		return { funnelType: DEFAULT_FUNNEL_TYPE, paywallRoute: DEFAULT_PAYWALL_ROUTE }
	}

	const currentAreaConfig = domainConfig[prefix]
	const funnels = currentAreaConfig?.funnels
	if (!funnels) {
		return { funnelType: DEFAULT_FUNNEL_TYPE, paywallRoute: DEFAULT_PAYWALL_ROUTE }
	}

	const funnelByExp = funnels.find(
		(funnel) => funnel.expId && experimentCallback(funnel.expId, Boolean(funnel.exposureImmediate)),
	)
	if (funnelByExp) {
		const tags = funnelByExp?.userTags || currentAreaConfig?.userTags
		const flags = currentAreaConfig?.userFlags
		return {
			funnelType: findFunnelById(funnelByExp.funnelId),
			paywallRoute: funnelByExp.paywallRoute,
			userTags: tags,
			userFlags: flags,
		}
	}

	const funnelByCountry =
		funnels.find((funnel) => funnel.country === country) || funnels.find((funnel) => funnel.country === 'default')
	if (funnelByCountry) {
		const tags = funnelByCountry?.userTags || currentAreaConfig?.userTags
		const flags = currentAreaConfig?.userFlags
		return {
			funnelType: findFunnelById(funnelByCountry.funnelId),
			paywallRoute: funnelByCountry.paywallRoute,
			userTags: tags,
			userFlags: flags,
		}
	}

	errorCallback('No funnel found for this combination', { domain, prefix, country })
	return { funnelType: DEFAULT_FUNNEL_TYPE, paywallRoute: DEFAULT_PAYWALL_ROUTE }
}
