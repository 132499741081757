import { browserEngine, browserName, browserVersion, os, version } from '@/helpers/device'
import { ENV_DEV_OR_STAGING, ENV_HACKERONE, ENV_LOCAL, VUE_APP_VERSION } from '@/helpers/environment'

class OpenTelemetry {
	tracer = null
	navigationObserver = null
	modules = {}
	spans = {}
	queue = {
		startSpan: [],
		endSpan: [],
	}

	constructor(options) {
		this.startTime = options.startTime

		this.#createPerformanceObservers()

		setTimeout(() => {
			this.#init(options)
		}, options.delay)
	}

	#loadModules() {
		return Promise.all([
			import(
				/* webpackChunkName: "opentelemetry", webpackExports: [
					"Resource"
				] */ '@opentelemetry/resources'
			),
			import(
				/* webpackChunkName: "opentelemetry", webpackExports: [
					"trace"
				] */ '@opentelemetry/api'
			),
			import(
				/* webpackChunkName: "opentelemetry", webpackExports: [
					"SemanticResourceAttributes"
				] */ '@opentelemetry/semantic-conventions'
			),
			import(
				/* webpackChunkName: "opentelemetry", webpackExports: [
					"BatchSpanProcessor",
					"ConsoleSpanExporter",
					"ParentBasedSampler",
					"SimpleSpanProcessor",
					"TraceIdRatioBasedSampler",
					"WebTracerProvider"
				] */ '@opentelemetry/sdk-trace-web'
			),
			import(
				/* webpackChunkName: "opentelemetry", webpackExports: [
					"OTLPTraceExporter"
				] */ '@opentelemetry/exporter-trace-otlp-http'
			),
		]).then((modules) => {
			const [
				{ Resource },
				{ trace },
				{ SemanticResourceAttributes },
				{
					BatchSpanProcessor,
					ConsoleSpanExporter,
					ParentBasedSampler,
					SimpleSpanProcessor,
					TraceIdRatioBasedSampler,
					WebTracerProvider,
				},
				{ OTLPTraceExporter },
			] = modules

			return {
				Resource,
				trace,
				SemanticResourceAttributes,
				BatchSpanProcessor,
				ConsoleSpanExporter,
				ParentBasedSampler,
				SimpleSpanProcessor,
				TraceIdRatioBasedSampler,
				WebTracerProvider,
				OTLPTraceExporter,
			}
		})
	}

	async #init(options) {
		this.modules = await this.#loadModules()

		if (!this.startTime || !this.modules) {
			return
		}

		const provider = new this.modules.WebTracerProvider({
			sampler: new this.modules.ParentBasedSampler({
				root: new this.modules.TraceIdRatioBasedSampler(options.samplerRatio),
			}),
			resource: new this.modules.Resource({
				[this.modules.SemanticResourceAttributes.SERVICE_NAME]: options.serviceName,
				[this.modules.SemanticResourceAttributes.SERVICE_NAMESPACE]: options.serviceNamespace,
				[this.modules.SemanticResourceAttributes.SERVICE_VERSION]: VUE_APP_VERSION,
				[this.modules.SemanticResourceAttributes.OS_NAME]: os,
				[this.modules.SemanticResourceAttributes.OS_VERSION]: version,
				'browser.name': browserName,
				'browser.engine': browserEngine,
				'browser.version': browserVersion,
			}),
		})
		const exporter = new this.modules.OTLPTraceExporter({ url: options.collectorUrl })

		provider.register()

		if (options.debug) {
			provider.addSpanProcessor(new this.modules.SimpleSpanProcessor(new this.modules.ConsoleSpanExporter()))
		}

		if (!ENV_LOCAL) {
			provider.addSpanProcessor(
				new this.modules.BatchSpanProcessor(exporter, {
					maxExportBatchSize: 75,
					maxQueueSize: 128,
				}),
			)
		}

		this.tracer = provider.getTracer('default')

		this.#processQueue()
	}

	#processQueue() {
		while (this.queue.startSpan.length) {
			const queueItem = this.queue.startSpan.shift()
			this.startSpan(queueItem.spanId, queueItem.options)
		}

		while (this.queue.endSpan.length) {
			const queueItem = this.queue.endSpan.shift()
			this.endSpan(queueItem.spanId, queueItem.endTime)
		}
	}

	#createPerformanceObservers() {
		this.startSpan('app-start', {
			startTime: this.startTime,
			attributes: { 'span.type': 'app-start' },
		})

		if (!window.PerformanceObserver) {
			this.endSpan('app-start')
			return
		}

		this.navigationObserver = new PerformanceObserver((list) => {
			const performanceTiming = list.getEntries()[0] ?? {}

			if (performanceTiming.entryType === 'navigation') {
				this.endSpan('app-start')

				this.navigationObserver?.disconnect()
			}
		})
		this.navigationObserver.observe({ entryTypes: ['navigation'] })
	}

	#beforeAppStartSpanEnd(spanId) {
		const firstPaintMetrics = window.performance.getEntriesByName('first-paint')?.[0]
		const firstContentfulPaintMetrics = window.performance.getEntriesByName('first-contentful-paint')?.[0]
		const navigationMetrics = window.performance.getEntriesByType('navigation')?.[0]
		const startTime = this.startTime - navigationMetrics?.responseEnd

		this.#setSpanAttributes(spanId, {
			'perf.duration': navigationMetrics?.duration,
			'perf.encoded_body_size': navigationMetrics?.encodedBodySize,
		})

		this.startSpan('html-dns-lookup', {
			startTime: startTime,
			attributes: { 'span.type': 'html-metric' },
		}).end(startTime + navigationMetrics?.domainLookupEnd)
		this.startSpan('html-ttfb', {
			startTime: startTime,
			attributes: { 'span.type': 'html-metric' },
		}).end(startTime + navigationMetrics?.responseStart)
		this.startSpan('html-response', {
			startTime: startTime,
			attributes: { 'span.type': 'html-metric' },
		}).end(startTime + navigationMetrics?.responseEnd)
		this.startSpan('perf-dom-interactive', {
			startTime: startTime,
			attributes: { 'span.type': 'perf-metric' },
		}).end(startTime + navigationMetrics?.domInteractive)
		this.startSpan('perf-dom-complete', {
			startTime: startTime,
			attributes: { 'span.type': 'perf-metric' },
		}).end(startTime + navigationMetrics?.domComplete)

		if (firstPaintMetrics) {
			this.startSpan('perf-first-paint', {
				startTime: startTime,
				attributes: { 'span.type': 'perf-metric' },
			}).end(startTime + firstPaintMetrics?.startTime)
		}

		if (firstContentfulPaintMetrics) {
			this.startSpan('perf-first-contentful-paint', {
				startTime: startTime,
				attributes: { 'span.type': 'perf-metric' },
			}).end(startTime + firstContentfulPaintMetrics?.startTime)
		}

		if (ENV_DEV_OR_STAGING) {
			/* eslint-disable no-console */
			console.groupCollapsed('%cOpentelemetry [debug]', 'font-weight: 400')
			console.log(this.spans)
			console.groupEnd()
			/* eslint-enable no-console */
		}
	}

	/**
	 * @param {string} spanId - ID of particular span
	 * @returns {import('@opentelemetry/api').Span}
	 */
	#getSpan(spanId) {
		if (!this.spans.hasOwnProperty(spanId)) {
			return
		}

		return this.spans[spanId]
	}

	/**
	 *
	 * @param {string} spanId - ID of particular span
	 * @param {import('@opentelemetry/api').SpanAttributes} attributes
	 */
	#setSpanAttributes(spanId, attributes = {}) {
		if (!this.spans.hasOwnProperty(spanId)) {
			return
		}

		this.spans[spanId].setAttributes(attributes)
	}

	/**
	 * @param {string} spanId - ID of particular span
	 * @param {import('@opentelemetry/api').SpanOptions} [options={}] - Options for span
	 * @returns {import('@opentelemetry/api').Span}
	 */
	startSpan(spanId, options = {}) {
		if (!this.tracer) {
			if (!options.startTime) {
				options.startTime = Date.now()
			}

			this.queue.startSpan.push({ spanId, options: { ...options } })

			return
		}

		const span = this.tracer.startSpan(spanId, options)

		this.spans[spanId] = span

		return span
	}

	/**
	 * @param {string} spanId - ID of particular span
	 * @param {number|null} endTime - End time of span
	 * @param spanId
	 */
	endSpan(spanId, endTime = undefined) {
		if (!this.tracer) {
			this.queue.endSpan.push({ spanId, endTime: Date.now() })

			return
		}

		const span = this.#getSpan(spanId)

		if (!span || span.ended) {
			return
		}

		if (spanId === 'app-start') {
			this.#beforeAppStartSpanEnd(spanId)
		}

		this.#setSpanAttributes(spanId, {
			'location.hostname': window.location.hostname,
			'location.pathname': window.location.pathname,
		})

		span.end(endTime)
	}
}

export default new OpenTelemetry({
	delay: 1000,
	debug: false,
	samplerRatio: 0.15,
	serviceName:
		ENV_DEV_OR_STAGING || ENV_HACKERONE ? 'simple-web-onboarding-development' : 'simple-web-onboarding-production',
	serviceNamespace: 'simple-web',
	collectorUrl: 'https://otel-external.fstr.app/v1/traces',
	startTime: window.ot.startTime,
})
