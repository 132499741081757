export default {
	id: 'SIMPLE_PREMIUM_1M_38_95_1W_6_93',
	providers: {
		paltaMap: {
			USD: 'simple_premium_1m_38_95_1w_6_93_usd',
		},
	},
	trial: {
		days: 7,
		priceMap: {
			USD: 6.93,
		},
		originalPriceMap: {
			USD: 17.77,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 1,
		priceMap: {
			USD: 38.95,
		},
	},
	options: ($t) => ({
		explicitTermsPeriodText: $t('pages.program.organic.cheap_and_short.plans.7_days.into_period_text'), // 'first week'
		explicitTermsIntervalText: $t('pages.program.organic.cheap_and_short.plans.7_days.sub_period_text'), // 'monthly'
		billingSubtitlePeriodText: $t('pages.program.cheap_and_short.plans.7_days.into_period_text'), // 'first week'
		footerPeriodText: $t('pages.program.cheap_and_short.plans.7_days.period_text'), // '1-week'
		footerIntervalText: $t('pages.program.cheap_and_short.plans.7_days.sub_period_text'), // 'monthly',
	}),
}
