import products from '@/data/products/index'

export function isSubscriptionProductExistInList(productList, subscription) {
	const { currency, product_id } = subscription

	return productList.some((productId) => {
		const product = products[productId]

		if (!product) {
			return false
		}

		const paltaMap = product.providers.paltaMap

		return paltaMap[currency] && paltaMap[currency] === product_id
	})
}
