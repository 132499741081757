import { Analytics } from '@frontend/simple-growth-frontend-tools'

export default {
	install: (Vue, { options, router, store }) => {
		const analytic = new Analytics(options)

		router.beforeEach((to, from, next) => {
			const hasAnalyticData = Boolean(to.meta.pageAnalyticUxEvent)

			if (hasAnalyticData) {
				const { name, data } = to.meta.pageAnalyticUxEvent

				analytic.logEvent(name, data)
			}

			next()
		})

		Vue.prototype.$analytic = {
			logEvent: (eventName, eventProperties) => {
				const funnelType = store.getters['location/getFunnelType']
				return analytic.logEvent(eventName, {
					url: window.location.href,
					funnel_type: funnelType.slug,
					funnel_id: funnelType.id,
					...eventProperties,
				})
			},
			setUserProperties: (...args) => analytic.setUserProperties(...args),
			initDestinationPlugins: (...args) => analytic.initDestinationPlugins(...args),
			setUserId: (...args) => analytic.setUserId(...args),
		}
	},
}
