import { mapState, mapGetters, mapMutations } from 'vuex'
import { os, version, browserEngine, browserName, browserVersion } from '@/helpers/device'

export default {
	data: () => ({
		isHotjarInitialized: false,
		isHotjarConsentPopupVisible: false,
	}),
	computed: {
		...mapGetters({
			requiredResourcesAreReady: 'status/requiredResourcesAreReady',
			countryCode: 'getCountryCode',
			userId: 'getUserId',
			getQueryParam: 'location/getQueryParam',
			getUserFlags: 'userFlags/getFlags',
			onetrustActiveGroups: 'onetrust/getActiveGroups',
		}),
		...mapState('onetrust', {
			bannerClosed: 'bannerClosed',
		}),
		isReadyToShowHotjarPopup() {
			return (
				this.requiredResourcesAreReady && this.bannerClosed && this.onetrustActiveGroups.includes('targeting_cookies')
			)
		},
	},
	watch: {
		isReadyToShowHotjarPopup(value) {
			if (value) {
				this.showHotjarPopup()
			}
		},
	},
	methods: {
		...mapMutations({
			updateLocalFlags: 'userFlags/updateLocalFlags',
		}),

		showHotjarPopup() {
			let hotjarConsentNeeded = false
			if (this.IS_MALE_PAYWALL_OPTIMIZATION_EXP && this.IS_MALE_PAYWALL_OPTIMIZATION_HOTJAR_EXP) {
				hotjarConsentNeeded = true
			}
			if (this.IS_MALE_FUNNEL_HOTJAR_EXP) {
				hotjarConsentNeeded = true
			}
			if (this.IS_FEMALE_FUNNEL_HOTJAR_EXP) {
				hotjarConsentNeeded = true
			}

			if (hotjarConsentNeeded) {
				if (!this.isHotjarInitialized) {
					this.$hj.init()
				}

				if (!this.getUserFlags.local.hotjarConsentPassed) {
					this.isHotjarConsentPopupVisible = true
				}
			}
		},

		handleHotjarConsentApproval() {
			this.isHotjarConsentPopupVisible = false
			this.$hj.startSession(this.userId, {
				deviceOs: os,
				deviceVersion: version,
				browserEngine,
				browserName,
				browserVersion,
				adgroupId: this.getQueryParam('adgroup_id'),
				adId: this.getQueryParam('ad_id'),
				campaignId: this.getQueryParam('campaign_id'),
				utmGender: this.getQueryParam('utm_gender'),
				utmMedium: this.getQueryParam('utm_medium'),
				utmPartnerId: this.getQueryParam('utm_partner_id'),
				utmSource: this.getQueryParam('utm_source'),
				utmCampaign: this.getQueryParam('utm_campaign') ? decodeURIComponent(this.getQueryParam('utm_campaign')) : null,
				utmContent: this.getQueryParam('utm_content') ? decodeURIComponent(this.getQueryParam('utm_content')) : null,
				utmTerm: this.getQueryParam('utm_term') ? decodeURIComponent(this.getQueryParam('utm_term')) : null,
			})
			this.updateLocalFlags({ hotjarConsentPassed: true })
		},

		handleHotjarConsentDenial() {
			this.isHotjarConsentPopupVisible = false
			this.updateLocalFlags({ hotjarConsentPassed: true })
		},
	},
}
