export default {
	en: (choice) => {
		return choice != 1 ? 1 : 0
	},

	es: (choice) => {
		return choice != 1 ? 1 : 0
	},

	pt: (choice) => {
		return choice != 1 ? 1 : 0
	},

	de: (choice) => {
		return choice != 1 ? 1 : 0
	},

	it: (choice) => {
		return choice != 1 ? 1 : 0
	},

	fr: (choice) => {
		return choice > 1 ? 1 : 0
	},

	/**
	 * 0 машин | {n} машина | {n} машины | {n} машин
	 */
	ru: (choice, choicesLength) => {
		if (choice === 0) {
			return 0
		}

		const teen = choice > 10 && choice < 20
		const endsWithOne = choice % 10 === 1

		if (choicesLength < 4) {
			return !teen && endsWithOne ? 1 : 2
		}
		if (!teen && endsWithOne) {
			return 1
		}
		if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
			return 2
		}

		return choicesLength < 4 ? 2 : 3
	},
}
