import config from '@/config'

export default {
	id: 'SIMPLE_CANCELLATION_9M_79_2_9M_99',
	providers: {
		stripe: config('stripe_cancel_offer_9_month'),
		braintree: 'pro_cancel_offer',
		paltaMap: {
			USD: 'simple_premium_9m_99_9m_79_2_usd',
			EUR: 'simple_premium_9m_99_9m_79_2_eur',
			GBP: 'simple_premium_9m_99_9m_79_2_gbp',
			AUD: 'simple_premium_9m_149_9m_119_2_aud',
			CAD: 'simple_premium_9m_149_9m_119_2_cad',
			DKK: 'simple_premium_9m_739_9m_591_2_dkk',
			SEK: 'simple_premium_9m_1179_9m_943_2_sek',
			NOK: 'simple_premium_9m_1179_9m_943_2_nok',
			BRL: 'simple_premium_9m_489_9m_391_2_brl',
			MXN: 'simple_premium_9m_1659_9m_1327_2_mxn',
		},
	},
	trial: {
		days: 270,
		priceMap: {
			USD: 79.2,
			EUR: 79.2,
			GBP: 79.2,
			AUD: 119.2,
			CAD: 119.2,
			DKK: 591.2,
			SEK: 943.2,
			NOK: 943.2,
			BRL: 391.2,
			MXN: 1327.2,
		},
		originalPriceMap: {
			USD: 99,
			EUR: 99,
			GBP: 99,
			AUD: 149,
			CAD: 149,
			DKK: 739,
			SEK: 1179,
			NOK: 1179,
			BRL: 489,
			MXN: 1659,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 9,
		priceMap: {
			USD: 99,
			EUR: 99,
			GBP: 99,
			AUD: 149,
			CAD: 149,
			DKK: 739,
			SEK: 1179,
			NOK: 1179,
			BRL: 489,
			MXN: 1659,
		},
	},
	options: () => ({
		footerPeriodText: '9-month',
	}),
}
