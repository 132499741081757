import Vue from 'vue'
import VueRouter, { isNavigationFailure } from 'vue-router'
import Sentry from '@/modules/Sentry'

import store from '@/store'
import { loadLanguageAsync } from '@/i18n'
import LandingSwap from '@/components/LandingSwap'
import ScreenLayout from '@/components/layouts/ScreenLayout'
import { ENV_DEV_OR_STAGING } from '@/helpers/environment'

import accountRoutes from '@/modules/Account/accountRoutes'
import subscriptionRoutes from '@/router/account/subscriptionRoutes'
import paywallRoutes from '@/modules/Paywall/paywallRoutes'
import manageSubscriptionRoutes from '@/router/account/manageSubscriptionRoutes'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		component: ScreenLayout,
		children: [
			{
				path: '',
				name: 'landing',
				component: LandingSwap,
				meta: {
					isLanding: true,
					hideHeader: true,
				},
			},

			{
				path: 'account',
				alias: '/survey/account',
				component: ScreenLayout,
				props: {
					hideHeader: true,
				},
				children: [
					{
						path: '',
						name: 'account',
						component: () => import(/* webpackChunkName: "account" */ '@/views/account/Account.vue'),
						meta: {
							requiresAuth: true,
							requiresSubscription: true,
							requiresUser: true,
							requiresUserFlags: true,
						},
					},
					...accountRoutes,
					...manageSubscriptionRoutes,
					...subscriptionRoutes,
				],
			},

			{
				path: 'faq',
				name: 'faq',
				alias: '/survey/faq',
				component: () => import(/* webpackChunkName: "srv" */ '@/views/Faq.vue'),
				meta: {
					hideHeader: true,
				},
			},

			{
				path: 'contact-us',
				name: 'contact-us',
				alias: '/survey/contact-us',
				component: () => import(/* webpackChunkName: "srv" */ '@/views/ContactUs.vue'),
				meta: {
					hideHeader: true,
				},
			},

			{
				path: 'result_complete',
				name: 'result_complete',
				alias: '/survey/result_complete',
				component: () => import(/* webpackChunkName: "result" */ '@/views/ResultComplete.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'health_notice',
				name: 'health_notice',
				alias: '/survey/health_notice',
				component: () => import(/* webpackChunkName: "result" */ '@/views/HealthNotice.vue'),
				meta: {
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'OnboardingHealthNoticeScreen',
						},
					},
				},
			},

			{
				path: 'name-form',
				name: 'name-form',
				component: () => import(/* webpackChunkName: "result" */ '@/views/NameForm.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'plan_ready',
				name: 'plan_ready',
				alias: '/survey/plan_ready',
				redirect: {
					name: 'plan-main',
				},
			},

			{
				path: 'login',
				name: 'create_account',
				alias: '/survey/create_account',
				component: () => import(/* webpackChunkName: "login" */ '@/views/Login.vue'),
				meta: {
					requiresUser: true,
					gtm: 'login',
				},
			},

			{
				path: 'signin',
				name: 'signin',
				component: () => import(/* webpackChunkName: "signin" */ '@/views/SignIn.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'AccountSignInScreen',
						},
					},
				},
				props: {
					mode: 'default',
				},
			},

			{
				path: 'signin-auth0',
				name: 'signin-auth0',
				component: () => import(/* webpackChunkName: "signin" */ '@/views/SignIn.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'AccountSignInScreen',
						},
					},
				},
				props: {
					mode: 'auth0',
				},
			},

			{
				path: 'signin-email-code-handler',
				name: 'signin-email-code-handler',
				component: () => import(/* webpackChunkName: "signin-email-handler" */ '@/views/SignInEmailHandler.vue'),
				meta: {
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'SignEmailCodeHandlerScreen',
						},
					},
				},
			},

			{
				path: 'login_by_code',
				name: 'login_by_code',
				alias: '/survey/login_by_code',
				component: () => import(/* webpackChunkName: "login_by_code" */ '@/views/LoginByCode.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'download',
				name: 'download',
				alias: '/survey/download',
				component: () => import(/* webpackChunkName: "update-profile" */ '@/views/download/Index'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'DownloadAppScreen',
						},
					},
				},
			},

			{
				path: 'cancel_subscription',
				name: 'cancel_subscription',
				alias: '/survey/cancel_subscription',
				component: () => import(/* webpackChunkName: "account" */ '@/views/account/CancelSubscription.vue'),
				meta: {
					requiresAuth: true,
					requiresSubscription: true,
					requiresUser: true,
				},
			},

			{
				path: 'confirm_email',
				name: 'confirm_email',
				alias: '/survey/confirm_email',
				component: () => import(/* webpackChunkName: "account" */ '@/views/account/ConfirmEmail.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
				},
			},

			{
				path: 'change_email',
				name: 'change_email',
				alias: '/survey/change_email',
				component: () => import(/* webpackChunkName: "account" */ '@/views/account/ChangeEmail.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'special-upsale',
				name: 'special-upsale',
				alias: '/survey/special-upsale',
				component: () => import(/* webpackChunkName: "upsell" */ '@/views/upsell/SpecialUpsale.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upgrade',
				name: 'upgrade',
				alias: '/survey/upgrade',
				component: () => import(/* webpackChunkName: "upsell" */ '@/views/upsell/Upgrade.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-essential',
				name: 'upsell-essential',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'essentialGuides' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-essential-updated',
				name: 'upsell-essential-updated',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'essentialGuidesUpdated' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'optimized-upsell',
				name: 'optimized-upsell',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'optimizedUpsell' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-activity-female',
				name: 'upsell-activity-female',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'activityFemale' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-activity-face-massage',
				name: 'upsell-activity-face-massage',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'faceMassageFakedoor' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'face-massage',
				name: 'face-massage',
				component: () => import(/* webpackChunkName: "upsell" */ '../components/upsell/UpsellFaceMassage.vue'),
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'upsell-sexual-health',
				name: 'upsell-sexual-health',
				component: () => import(/* webpackChunkName: "upsell" */ '../views/upsell/Upsell.vue'),
				props: { scenarioId: 'sexualHealth' },
				meta: {
					hideHeader: true,
					requiresUser: true,
					hideProgress: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'update-profile',
				name: 'update-profile',
				alias: '/survey/update-profile',
				component: () => import(/* webpackChunkName: "update-profile" */ '@/views/UpdateProfile.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'UpdateProfileScreen',
						},
					},
				},
			},

			{
				path: 'redirect',
				name: 'redirect',
				component: () => import(/* webpackChunkName: "redirect" */ '@/views/Redirect.vue'),
				meta: {
					requiresUser: true,
				},
			},

			{
				path: 'personalized-plan-activity',
				name: 'personalized-plan-activity',
				component: () =>
					import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/PersonalizedPlanActivity.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_PLAN_INFO_SCREEN',
						},
					},
				},
			},

			{
				path: 'trustpilot-rating',
				name: 'trustpilot-rating',
				component: () => import(/* webpackChunkName: "pre-paywall" */ '@/views/pre-paywall/TrustpilotRating.vue'),
				meta: {
					requiresUser: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_WHAT_YOU_GET_INFO_SCREEN',
						},
					},
				},
			},

			{
				path: 'personal-plan',
				name: 'personal-plan',
				component: () => import(/* webpackChunkName: "your-health-coach" */ '@/views/personal-plan/PersonalPlan.vue'),
				meta: {
					requiresUser: true,
					requiresSubscription: true,
				},
			},

			{
				path: 'subscription-activated',
				name: 'subscription-activated',
				component: () => import(/* webpackChunkName: "subscription-activated" */ '@/views/SubscriptionActivated.vue'),
				meta: {
					requiresUser: true,
					requiresSubscription: true,
					hideHeader: true,
					pageAnalyticUxEvent: {
						name: 'ScreenView',
						data: {
							title: 'ONBOARDING_CONGRATS_ON_SUBSCRIPTION_SCREEN',
						},
					},
				},
			},
		],
	},

	...paywallRoutes,

	{
		path: '/men',
		name: 'male-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/women',
		name: 'female-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/women-comm',
		name: 'female-funnel-comm',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/active-women',
		name: 'female-activity-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/active-women/survey/ob/:page*',
		name: 'female-activity-onboarding',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	{
		path: '/men/survey/:page*',
		name: 'male-survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
		meta: {
			requiresFunnelCheck: true,
		},
	},

	{
		path: '/men/survey/prev/:page*',
		name: 'male-survey-legacy',
		component: () => import(/* webpackChunkName: "strapi-survey" */ '@/views/StrapiSurvey.vue'),
	},

	{
		path: '/women-pilates',
		name: 'female-pilates-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/women-pilates/survey/ob/:page*',
		name: 'female-activity-pilates',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	{
		path: '/women-yoga',
		name: 'female-yoga-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/women-yoga/survey/ob/:page*',
		name: 'female-activity-chair-yoga',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	{
		path: '/somatic-yoga',
		name: 'somatic-yoga-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},

	{
		path: '/somatic-yoga/survey/ob/:page*',
		name: 'female-activity-somatic-yoga',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	{
		path: '/walking',
		name: 'walking-funnel',
		component: LandingSwap,
		meta: {
			isLanding: true,
		},
	},
	{
		path: '/walking/survey/ob/:page*',
		name: 'female-activity-walking',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
	},

	{
		path: '/survey/:page*',
		name: 'survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
		meta: {
			requiresFunnelCheck: true,
		},
	},

	{
		path: '/survey/prev/:page*',
		name: 'survey-legacy',
		component: () => import(/* webpackChunkName: "strapi-survey" */ '@/views/StrapiSurvey.vue'),
	},

	{
		path: '/women/survey/:page*',
		name: 'female-survey',
		component: () => import(/* webpackChunkName: "ob-survey" */ '@/modules/Onboarding/OnboardingView.vue'),
		meta: {
			requiresFunnelCheck: true,
		},
	},

	{
		path: '/women/survey/prev/:page*',
		name: 'female-survey-legacy',
		component: () => import(/* webpackChunkName: "strapi-survey" */ '@/views/StrapiSurvey.vue'),
	},

	{
		path: '/youtube/survey/:page*',
		name: 'youtube-survey',
		component: () => import(/* webpackChunkName: "strapi-survey" */ '@/views/StrapiSurvey.vue'),
	},

	{
		path: '/activity/survey/:page*',
		name: 'activity-survey',
		component: () => import(/* webpackChunkName: "strapi-survey" */ '@/views/StrapiSurvey.vue'),
	},

	{
		path: '/age-input',
		name: 'age-input',
		component: () => import(/* webpackChunkName: "age-input" */ '@/views/AgeInputScreen.vue'),
	},

	{
		path: '/assessment/report',
		name: 'assessment-report',
		component: () => import(/* webpackChunkName: "assessment-survey" */ '@/views/assessment/AssessmentReport.vue'),
		meta: {
			requiresUser: true,
		},
	},

	{
		path: '/assessment/result',
		name: 'assessmen-result',
		component: () => import(/* webpackChunkName: "assessment-survey" */ '@/views/assessment/AssessmentResult.vue'),
	},

	{
		path: '/assessment/female/:page*',
		name: 'assessmen-survey',
		component: () => import(/* webpackChunkName: "assessment-survey" */ '@/views/assessment/AssessmentSurvey.vue'),
		props: { instance: 'femaleAssessment' },
	},

	{
		path: '*',
		name: '404',
		component: () => import(/* webpackChunkName: "not-found" */ '@/views/EmptyPage.vue'),
	},
]

const router = new VueRouter({
	mode: 'history',
	routes,
	scrollBehavior() {
		return new Promise((resolve) => {
			setTimeout(() => {
				resolve({ x: 0, y: 0 })
			}, 0)
		})
	},
})

router.beforeEach(async (to, from, next) => {
	const blockingRequests = [
		store.dispatch('coreFeatures/hydrateCoreFeatures'),
		store.dispatch('fetchGeo', {
			country: to.query.country,
		}),
		loadLanguageAsync(),
	]

	if (!store.state.user) {
		const fetchUserRequest = store.dispatch('fetchUserFromAws')

		if (to.meta.requiresUser) {
			blockingRequests.push(fetchUserRequest)
		}
	}

	if (to.meta.requiresUserFlags) {
		blockingRequests.push(store.dispatch('userFlags/fetchUserFlags'))
	}

	await Promise.all(blockingRequests)
	store.commit('status/setResourceReadiness', 'network')

	let isAuthenticated = store.getters.isAuthenticated
	let isOnboarded = store.getters.isOnboarded

	if (to.meta.requiresAuth && !isAuthenticated) {
		next({
			name: 'create_account',
			query: {
				redirect: to.name,
			},
		})
		return
	}

	if (to.meta.requiresOnboarding && !isOnboarded) {
		next({
			name: 'survey',
			query: {
				track: true,
			},
			replace: true,
		})
		return
	}

	if (to.meta.requiresSubscription && !store.state.subscription && isAuthenticated) {
		next({
			name: 'plan-main',
			query: {
				plan: 'redirect', // FIXME: temporary solution for debug purposes
			},
			replace: true,
		})
		return
	}

	if (to.meta.requiresFunnelCheck) {
		const isObFunnelEnabled = store.getters['coreFeatures/FEAT_FUNNELS_OB_ENABLED']
		const LEGACY_FUNNELS_MAP = {
			survey: 'survey-legacy',
			'male-survey': 'male-survey-legacy',
			'female-survey': 'female-survey-legacy',
		}

		if (!isObFunnelEnabled && LEGACY_FUNNELS_MAP[to.name]) {
			next({
				name: LEGACY_FUNNELS_MAP[to.name],
			})
			return
		}
	}

	next()
})

const originalPushMethod = VueRouter.prototype.push
const originalReplaceMethod = VueRouter.prototype.replace

function navigationChangedHandler(originalMethod, location, onComplete, onAbort) {
	if (onComplete || onAbort) {
		return originalMethod.call(this, location, onComplete, onAbort)
	}

	return originalMethod.call(this, location).catch((err) => {
		// Ignore the NavigationDuplicated error on the console
		if (err.name === 'NavigationDuplicated') {
			return
		}

		if (ENV_DEV_OR_STAGING) {
			console.warn(err)
			Sentry.captureException(err)
		}

		if (isNavigationFailure(err)) {
			return err
		}

		return Promise.reject(err)
	})
}

VueRouter.prototype.push = function (location, onComplete, onAbort) {
	return navigationChangedHandler.call(this, originalPushMethod, location, onComplete, onAbort)
}

VueRouter.prototype.replace = function replace(location, onComplete, onAbort) {
	return navigationChangedHandler.call(this, originalReplaceMethod, location, onComplete, onAbort)
}

router.onError((error) => {
	if (error.name === 'ChunkLoadError') {
		Sentry.withScope((scope) => {
			scope.setTags({
				chunkLoadErrorRequest: error.request,
			})
			scope.captureException(error)
		})
	}
})

export default router
