import { isInteger } from '@/helpers/utils'

export default {
	install(Vue, { i18n }) {
		function priceFormat(value, currency, notation = 'standard', minimumFractionDigits = null) {
			const locale = window.language
			const formatOptions = {
				currency: currency.toUpperCase(),
				notation,
				style: 'currency',
				currencyDisplay: 'symbol',
			}

			if (minimumFractionDigits !== null) {
				// If we use minimumFractionDigits, then we need to show only this amount of digits
				formatOptions.minimumFractionDigits = minimumFractionDigits
			} else if (isInteger(value)) {
				// Else if we have integer, then we don't need to show any digits after comma
				formatOptions.maximumFractionDigits = 0
			}

			/** By default, in locale 'en' krones will be present like "SEK 9.73", to localize it properly we eigther need to use correct locales like 'en-SE' or switch to using currency name manually result of this is "9.73 kr" */
			if (['sek', 'nok', 'dkk', 'mxn'].includes(currency)) {
				formatOptions.currencyDisplay = 'name'
			}

			const formatter = new Intl.NumberFormat(locale, formatOptions)
			const parts = formatter.formatToParts(value)

			return parts
				.map((part) => {
					if (part.type === 'currency') {
						return i18n.t(`common.currencies_short.${currency.toLowerCase()}`)
					}
					return part.value
				})
				.join('')
		}

		Vue.prototype.$tp = priceFormat
	},
}
