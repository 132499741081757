import sha256 from 'crypto-js/sha256'
import sha1 from 'crypto-js/sha1'

/**
 * Hashes a string using SHA256 algorithm.
 * @param {string} string - The string to hash.
 * @returns {string} - The hashed string.
 */
export function hashSha256(string) {
	return sha256(string).toString()
}

/**
 * Hashes a string using SHA1 algorithm.
 * @param {string} string - The string to hash.
 * @returns {string} - The hashed string.
 */
export function hashSha1(string) {
	return sha1(string).toString()
}
