import config from '@/config'

export default {
	id: 'SIMPLE_CANCELLATION_3M_59_99_3M_29_99',
	providers: {
		stripe: config('stripe_cancel_offer_3_month'),
		braintree: 'pro_cancel_offer',
		paltaMap: {
			USD: 'simple_premium_3m_59_99_3m_29_99_usd',
			EUR: 'simple_premium_3m_59_99_3m_29_99_eur',
			GBP: 'simple_premium_3m_59_99_3m_29_99_gbp',
			AUD: 'simple_premium_3m_99_99_3m_43_99_aud',
			CAD: 'simple_premium_3m_99_99_3m_43_99_cad',
			DKK: 'simple_premium_3m_459_3m_229_dkk',
			SEK: 'simple_premium_3m_699_3m_349_sek',
			NOK: 'simple_premium_3m_699_3m_349_nok',
			BRL: 'simple_premium_3m_299_99_3m_159_99_brl',
			MXN: 'simple_premium_3m_999_3m_499_mxn',
		},
	},
	trial: {
		days: 90,
		priceMap: {
			USD: 29.99,
			EUR: 29.99,
			GBP: 29.99,
			AUD: 43.99,
			CAD: 43.99,
			DKK: 229,
			SEK: 349,
			NOK: 349,
			BRL: 159.99,
			MXN: 499,
		},
		originalPriceMap: {
			USD: 59.99,
			EUR: 59.99,
			GBP: 59.99,
			AUD: 99.99,
			CAD: 99.99,
			DKK: 459,
			SEK: 699,
			NOK: 699,
			BRL: 299.99,
			MXN: 999,
		},
	},
	subscription: {
		interval: 'month',
		intervalCount: 3,
		priceMap: {
			USD: 59.99,
			EUR: 59.99,
			GBP: 59.99,
			AUD: 99.99,
			CAD: 99.99,
			DKK: 459,
			SEK: 699,
			NOK: 699,
			BRL: 299.99,
			MXN: 999,
		},
	},
	options: ($t) => ({
		footerPeriodText: $t('pages.program.cheap_and_short.plans.90_days.period_text'), // '3-month'
	}),
}
