import config from '@/config'

export default {
	id: 'SIMPLE_UPGRADE_6M_63_2',
	providers: {
		stripe: config('stripe_cheap_and_short_upgrade_6_month'),
		braintree: 'pro_short_cheap_upgrade',
		paltaMap: {
			USD: 'simple_premium_6m_63_2_usd',
			EUR: 'simple_premium_6m_63_2_eur',
			GBP: 'simple_premium_6m_63_2_gbp',
			AUD: 'simple_premium_6m_95_aud',
			CAD: 'simple_premium_6m_95_2_cad',
			DKK: 'simple_premium_6m_471_2_dkk',
			SEK: 'simple_premium_6m_751_2_sek',
			NOK: 'simple_premium_6m_751_2_nok',
			BRL: 'simple_premium_6m_311_2_brl',
			MXN: 'simple_premium_6m_1055_2_mxn',
		},
	},
	trial: null,
	subscription: {
		interval: 'month',
		intervalCount: 6,
		priceMap: {
			USD: 63.2,
			EUR: 63.2,
			GBP: 63.2,
			AUD: 95.2,
			CAD: 95.2,
			DKK: 471.2,
			SEK: 751.2,
			NOK: 751.2,
			BRL: 311.2,
			MXN: 1055.2,
		},
	},
	options: () => ({}),
}
