export const PAYMENT_METHODS = {
	card: 1,
	paypal: 1,
	apple: 1,
}

export const FEATURES = {
	SIMPLE_PREMIUM: 'simple_premium',
	WORKBOOK_BUNDLE: 'simple_workbook_bundle',
}

export const CANCELATION_REASON = {
	EXPENSIVE: 'too_much_expensive',
	COMPETITOR: 'competitor',
	DO_NOT_USE: 'do_not_use',
	WANTED_TO_TRY_NOT_ENOUGH_VALUE: 'wanted_to_try_not_enough_value',
	WANTED_TO_TRY_WITHOUT_BEING_SUBSCRIBER: 'wanted_to_try_without_being_subscribed',
	REACHED_MY_GOAL: 'reached_my_goal',
	NEVER_SUBSCRIBED_IN_FIRST_PLACE: 'never_subscribed_in_first_place',
	FORGOT_TO_UNSUBSCRIBE_EARLIER: 'forgot_to_unsubscribe_earlier',
	UNKNOWN_PREFER_NOT_TO_SAY: 'unknown_prefer_not_to_say',
}
